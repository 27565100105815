import './styles.scss';
import { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../../context/Context';
import { PHOTO_SECTIONS } from '../../../context/photoConstants';
import { IAppContext, Photo } from '../../../types';
import { Field, FieldSection, TabDataSections } from '../../../types/form';
import ContinueButton from '../../carousel/ContinueButton';
import { ReactComponent as CheckmarkIcon } from '../../../assets/imgs/photo-menu/checkmark.svg';
import {
  filterSectionsByDependencies,
  validationForm,
} from '../../../helpers/Utils';
import FieldContent from '../../../screens/FieldContent';
import PATH_ROUTES from '../../../static/constants/path-routes';
// import Resume from '../../../screens/Resume';
import { Alert } from '@mui/material';
import {
  getPreInspection,
  savePreInspection,
  syncAlertsWithForm,
} from '../../../services/preInspect.service';
import { ActionType } from '../../../context/actions';

interface Props {
  sections: FieldSection[];
  setSections: (sections: FieldSection[]) => void;
  setTabClick: (valid: boolean) => void;
  activeStep: number;
  nextStep: number;
  tabOrder: number;
  preId: string;
  goToBackStep: (request: boolean) => void;
}

const TabContent = ({
  sections,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSections,
  setTabClick,
  activeStep,
  nextStep,
  tabOrder,
  preId,
  goToBackStep,
}: Props): JSX.Element => {
  const history = useHistory();
  const { state, dispatch } = useContext(AppContext) as IAppContext;
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const links = Object.entries(PHOTO_SECTIONS).map(
    ([photoSectionId, photoSection]) => {
      const done = photoSection.photoIds
        .map((photoId) => state.photos[photoId])
        .every((photo: Photo) => Boolean(photo.url));

      return (
        <Link
          key={photoSectionId}
          className="photo-section-link"
          to={`/photo-sections/${photoSectionId}`}
        >
          <div className="label">
            {photoSection.icon}
            <span>{photoSection.title}</span>
          </div>
          {done && (
            <CheckmarkIcon width={30} height={30} className="theme-svg-icon" />
          )}
        </Link>
      );
    },
  );
  const filterSections = sections
    .filter((section: FieldSection) => {
      return (
        section.parentSectionId === activeStep &&
        filterSectionsByDependencies(section, sections)
      );
    })
    .sort((a, b) => (a.idOrder > b.idOrder ? 1 : -1));

  const formValidation = (): { isForm: boolean; result: any } => {
    const currentSection = sections.filter(
      (f: FieldSection) => f.parentSectionId === activeStep,
    )[0];

    const isForm =
      currentSection.name === undefined ||
      currentSection.name === null ||
      currentSection.name.length === 0 ||
      currentSection.name === TabDataSections.PRE_GENERAL ||
      currentSection.name === TabDataSections.PRE_VEHICLE;
    const result: any = isForm
      ? validationForm(activeStep, sections, state.formDataInit)
      : undefined;

    setBtnDisabled(result ? !result.valid : false);
    setTabClick(result ? result.valid : true);

    return {
      isForm: isForm,
      result: result,
    };
  };

  const triggerNextStep = (): void => {
    const { isForm, result } = formValidation();

    if (isForm && result && result.sections) {
      setSections(result.sections);
      setTabClick(result.valid);
    }

    if (tabOrder < 0) {
      (async (): Promise<void> => {
        await syncAlertsWithForm(state, dispatch);
        await savePreInspection(state.formData, state, dispatch, true);
        history.replace(PATH_ROUTES.PRE_FINISH_SCREEN);
      })();
    } else if (nextStep > 0 && (!isForm || (result && result.valid))) {
      (async (): Promise<void> => {
        const objData = await getPreInspection(preId);
        dispatch({
          type: ActionType.SET_PREINSPECTION_MODEL,
          payload: objData,
        });
      })();

      history.replace(`/preinspections/${preId}/sections/${nextStep}`);
    }
  };

  useEffect(() => {
    goToBackStep(true);
    setBtnDisabled(
      !Object.values(state.photos).every((photo) => Boolean(photo.url)),
    );
  }, []);

  return (
    <>
      <div className="container preInspection">
        {filterSections.map((section: FieldSection, index: number) => {
          return (
            <div key={index}>
              {section.name === TabDataSections.PRE_PHOTOS && (
                <>
                  <h3 className="lead2">Selecciona un grupo para iniciar</h3>
                  <div className="photo-section-links">{links}</div>
                </>
              )}
              {section.name === TabDataSections.PRE_TERMS && (
                <>
                  <h3 className="lead2">Términos y Condiciones</h3>
                  {section.fields.map((dataField: Field) => {
                    return (
                      <FieldContent
                        key={dataField.idName}
                        field={dataField}
                        countryId={0}
                        isDisabled={dataField.isDisabled}
                        triggerValidation={formValidation}
                      />
                    );
                  })}
                  <Alert className="info-alert" severity="info">
                    Enviaremos copia de tu inspección al correo electrónico.
                  </Alert>
                </>
              )}
              {(String(section.name ?? '').length === 0 ||
                String(section.name ?? '') === TabDataSections.PRE_VEHICLE ||
                String(section.name ?? '') === TabDataSections.PRE_GENERAL) && (
                <>
                  <h3 className="lead">{section.title}</h3>
                  {section.fields.map((dataField: Field) => {
                    return (
                      <FieldContent
                        key={dataField.idName}
                        field={dataField}
                        countryId={0}
                        isDisabled={dataField.isDisabled}
                        triggerValidation={formValidation}
                      />
                    );
                  })}
                </>
              )}
            </div>
          );
        })}
      </div>
      <ContinueButton disabled={btnDisabled} onClick={triggerNextStep}>
        {tabOrder < 0 && <>Finalizar</>}
        {tabOrder > 0 && <>Continuar</>}
      </ContinueButton>
    </>
  );
};

export default TabContent;
