import localforage from 'localforage';
import { FC, useContext } from 'react';
import ContinueButton from '../components/carousel/ContinueButton';
import { ReactComponent as WelcomeImg } from '../assets/imgs/il_proceso.svg';
import './WelcomeScreen.scss';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../context/Context';
import { IAppContext } from '../types';
import PATH_ROUTES from '../static/constants/path-routes';

const WelcomeScreen: FC = () => {
  const history = useHistory();
  const {
    state: {
      preInspectionModel: { preInspectionId },
    },
  } = useContext(AppContext) as IAppContext;

  const handleContinue = async () => {
    await localforage.setItem('acceptConditions', true);
    if (preInspectionId > 0) {
      history.push(PATH_ROUTES.PRE_INFO_SCREEN);
    } else {
      history.push(PATH_ROUTES.PRE_ONBOARDING_SCREEN);
    }
  };

  return (
    <div className="welcome">
      <div className="welcome__intro">
        <h1>¡Hola!</h1>
        <p>
          Toma en cuenta que el proceso de inspección debe ser realizado en su
          totalidad por el Asegurado y/o el Corredor de Seguros.
        </p>
        <WelcomeImg />
      </div>
      <ContinueButton onClick={handleContinue}>
        Acepto y continuar
      </ContinueButton>
    </div>
  );
};

export default WelcomeScreen;
