import { FilterNone } from '@mui/icons-material';
import {
  Alert,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Pagination,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import QRCodeStyling from 'qr-code-styling';
import { FC, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { copyToClipboard } from '../helpers/Utils';
import ContinueButton from '../components/carousel/ContinueButton';
import emailImage from '../assets/imgs/email-icon.png';
import './SharedScreen.scss';
import {
  getPreInspection,
  sharedPreInspection,
} from '../services/preInspect.service';

type SharedScreenParams = {
  id: string;
};

const qrCode = new QRCodeStyling({
  width: 200,
  height: 200,
  dotsOptions: {
    type: 'dots',
  },
  cornersSquareOptions: {
    type: 'square',
  },
  cornersDotOptions: {
    type: 'square',
  },
});

const SharedScreen: FC = () => {
  const { id } = useParams<SharedScreenParams>();
  const qrCodeRef = useRef<HTMLElement>();
  const [emailSended, setEmailSended] = useState(false);
  const [emailIsRequired, setEmaiIsRequired] = useState(false);
  const [emailData, setEmailData] = useState('');
  const [typeData, setTypeData] = useState('qrOption');
  const [showQR, setShowQR] = useState(false);
  //const [generateQR, setGenerateQR] = useState(false);
  const [tabIndex, setTabIndex] = useState(1);
  const url = `${process.env.REACT_APP_PREINSPECTIONS_URL || ''}/${id}`;

  const sharedLink = async (type: string): Promise<void> => {
    const objData = await getPreInspection(id);
    if (objData.preInspectionId > 0) {
      await sharedPreInspection(
        objData.preInspectionId,
        objData.preCompanyId,
        type,
        emailData,
        url,
      );
    }
  };

  const emailOnChange = (event: any) => {
    if (event.target) {
      setEmailData(String(event.target.value).toLowerCase());
    }
  };

  const typeOnChange = (event: any) => {
    if (event.target) {
      setTypeData(event.target.value);
    }
  };

  const submitSharedType = () => {
    const typeToSend = typeData === 'emailOption' ? 'EMAIL' : 'QR';
    let showDefault = false;

    setEmaiIsRequired(false);
    setEmailSended(typeData === 'emailOption');
    setShowQR(typeData === 'qrOption');

    if (typeToSend === 'EMAIL' && emailData.length === 0) {
      setEmaiIsRequired(true);
      showDefault = true;
    }
    const index = typeToSend.length > 0 && !showDefault ? 2 : 1;
    tab(index);
    setTabIndex(index);

    if (index > 1 && typeToSend === 'EMAIL') {
      (async (): Promise<void> => {
        await sharedLink(typeToSend);
      })();
    }
  };

  const copyLink = () => {
    copyToClipboard(url || '');
  };

  const backTab = () => {
    setTabIndex(1);
    if (qrCodeRef.current && qrCodeRef.current.children.length > 0) {
      qrCodeRef.current?.removeChild(qrCodeRef.current.children[0]);
    }
  };

  const tab = (optionTab: number) => {
    if (optionTab === 2 && typeData === 'qrOption') {
      qrCode.update({
        data: url,
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      qrCode.append(qrCodeRef.current!);
    }

    switch (optionTab) {
      case 1:
        return (
          <>
            <div className="contentTab">
              <p>Ingrese la siguiente información:</p>
              <TextField
                id="email-client"
                label="Correo"
                variant="outlined"
                value={emailData}
                onChange={emailOnChange}
              />
              <Divider className="cp-divider">o</Divider>
              <p>
                Seleccione cómo desea habilitar el link para realizar la
                Inspección Virtual:
              </p>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={typeData}
                  name="radio-buttons-group"
                  onChange={typeOnChange}
                >
                  <FormControlLabel
                    value="qrOption"
                    control={<Radio />}
                    label="Código QR"
                  />
                  <FormControlLabel
                    value="emailOption"
                    control={<Radio />}
                    label="Correo Electrónico"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <ContinueButton onClick={submitSharedType}>
              Continuar
            </ContinueButton>
          </>
        );
      case 2:
        return (
          <>
            {emailSended && (
              <>
                <br />
                <h2>¡Enlace ha sido compartido!</h2>
                <h3>Por favor revisar tu bandeja de correo.</h3>
                <img src={emailImage} />
              </>
            )}

            {showQR && (
              <>
                <p>Escaneá este código QR</p>
                <Divider className="cp-divider">o</Divider>
                <p>Copia y comparte esta URL</p>
                <div className="cp-link">
                  <p>{url}</p>
                  <IconButton className="cp-link__button" onClick={copyLink}>
                    <FilterNone />
                  </IconButton>
                </div>
              </>
            )}
            <ContinueButton onClick={backTab}>Volver</ContinueButton>
          </>
        );
      default:
        tab(1);
    }
  };

  return (
    <Card className="shared-screen">
      {emailIsRequired && (
        <Alert severity="error">Correo Electrónico es requerido.</Alert>
      )}

      <CardContent className="shared-screen__content">
        <Pagination count={2} page={tabIndex} hidePrevButton hideNextButton />
        <div ref={qrCodeRef as React.RefObject<HTMLDivElement>} />
        {tab(tabIndex)}
      </CardContent>
    </Card>
  );
};

export default SharedScreen;
