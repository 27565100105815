import React, { FunctionComponent, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ContinueButton from '../components/carousel/ContinueButton';
import {
  MediaId,
  photoSectionIdFromUrlSlug,
  PHOTO_DEFINITIONS,
  PHOTO_SECTIONS,
  ValidationError,
} from '../context/photoConstants';
import './PhotoSectionScreen.scss';
import { IAppContext, Photo } from '../types';
import { AppContext } from '../context/Context';
import {
  deletePhotoFromServer,
  runValidations,
  uploadPhotoToServer,
} from '../helpers/photoUploader';
import { ActionType } from '../context/actions';
// import Loading from '../components/common/Loading';
import ValidationWarning from '../components/ValidationWarning';
import { ReactComponent as BackIcon } from '../assets/imgs/back.svg';
import { ReactComponent as InfoIcon } from '../assets/imgs/i-info.svg';
import { ReactComponent as ViewIcon } from '../assets/imgs/i-view.svg';
import Modal from '../components/Modal';
import { getHelpModalContents } from '../helpers/photoHelper';
import ProgressLoading from '../components/common/ProgressLoading';

function ImageUploadThumbnail({
  photo,
  onChange,
  onPreviewClick,
  onHelpClick,
}: {
  photo: Photo;
  onChange?: any;
  onPreviewClick?: any;
  onHelpClick?: any;
}): JSX.Element {
  const photoDefinition = PHOTO_DEFINITIONS[photo.id];

  if (photo.url) {
    return (
      <div className="image-preview-thumbnail">
        <img
          src={photo.url}
          alt={photoDefinition.title}
          onClick={() => onPreviewClick(photo)}
        />
        <div className="preview-label">{photoDefinition.shortTitle}</div>
      </div>
    );
  }
  const htmlId = `photo-${photo.id}`;

  return (
    <div className="image-upload-thumbnail">
      <label htmlFor={htmlId} className="custom-file-upload">
        {photoDefinition?.placeholder}
        {photoDefinition.title}
      </label>
      <div className="ayuda" onClick={() => onHelpClick(photo.id)}>
        <ViewIcon />
        <span>Ejemplo</span>
      </div>
      <input
        id={htmlId}
        type="file"
        accept="image/*,video/*"
        capture="environment"
        onChange={(e) => onChange(photo.id, e)}
      />
    </div>
  );
}

function FullScreenPreview({
  url,
  onRemove,
  onExit,
}: {
  url: string;
  onRemove: any;
  onExit: any;
}): JSX.Element {
  return (
    <div className="full-screen-preview">
      <img className="preview-photo" src={url} alt="Photo preview" />
      <div className="action-buttons">
        <button
          className="button button-danger button-block"
          onClick={onRemove}
        >
          Eliminar
        </button>
        <button className="button button-block" onClick={onExit}>
          Ok
        </button>
      </div>
    </div>
  );
}

const PhotoSectionScreen: FunctionComponent = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { state, dispatch } = useContext(AppContext) as IAppContext;
  const history = useHistory();
  const [showSectionHelp, setShowSectionHelp] = useState(true);
  const [helpModalId, setHelpModalId] = useState<MediaId | null>(null);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] =
    useState<ValidationError | null>(null);
  const [previewPhoto, setPreviewPhoto] = useState<Photo | null>(null);

  const photoSectionId = photoSectionIdFromUrlSlug(id);
  const photoSection = PHOTO_SECTIONS[photoSectionId];
  const photos = photoSection.photoIds.map((mediaId) => state.photos[mediaId]);

  const toggleSectionHelp = () => {
    setShowSectionHelp(!showSectionHelp);
  };
  const onChange = async (
    mediaId: MediaId,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files || !state.preInspectionId) {
      return;
    }

    setLoading(true);
    try {
      const { awsUrl, original } = await uploadPhotoToServer(
        state.preInspectionId,
        mediaId,
        event,
      );

      const [error, reduxAction] = await runValidations(
        mediaId,
        awsUrl,
        original,
      );
      if (!error) {
        dispatch({
          type: ActionType.ADD_PHOTO,
          payload: { id: mediaId, url: awsUrl },
        });
        if (reduxAction) {
          dispatch(reduxAction);
        }
      } else {
        await deletePhotoFromServer(awsUrl);
        setValidationError(error);
      }
    } catch (error) {
      // TODO: Sentry.captureException(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const removePhoto = async (id: MediaId, url?: string) => {
    if (url) {
      await deletePhotoFromServer(url);
      dispatch({
        type: ActionType.REMOVE_PHOTO,
        payload: { id: id },
      });
    }
    setPreviewPhoto(null);
  };
  const handleWarningButton = (): void => {
    setValidationError(null);
  };
  const clearHelpId = () => {
    setHelpModalId(null);
  };

  const helpContents = getHelpModalContents(helpModalId);
  const disabled = !photos.every((photo) => Boolean(photo.url));

  return (
    <div className="photo-section-screen">
      {showSectionHelp && (
        <Modal onBackdropClick={toggleSectionHelp}>
          <h2>{photoSection.title}</h2>
          {photoSection.helpModal}
          <ContinueButton onClick={toggleSectionHelp}>Entendido</ContinueButton>
        </Modal>
      )}
      {helpModalId && helpContents && (
        <Modal onBackdropClick={clearHelpId} className="photo-help-modal">
          <h2>{helpContents.title}</h2>
          {helpContents.imgUrl && (
            <img
              src={helpContents?.imgUrl}
              alt={helpContents.title}
              height={200}
            />
          )}
          {helpContents.paragraph}
          <ContinueButton onClick={clearHelpId}>Entendido</ContinueButton>
        </Modal>
      )}
      {/* {loading && <Loading message="Validando Fotografía" />} */}
      {/** Maybe if we want show all messages for each photo, we will need to define
       *  estimated time for each one and have a variable to track the current photo to
       * change the duration */}
      {loading && <ProgressLoading />}
      {validationError && (
        <ValidationWarning
          validationError={validationError}
          handleWarningButton={handleWarningButton}
        />
      )}
      <div className="container">
        <h3 className="lead">
          <BackIcon onClick={history.goBack} />
          <span>{PHOTO_SECTIONS[photoSectionId].title}</span>
          <InfoIcon onClick={toggleSectionHelp} />
        </h3>
        <div className="photos-scrollable-area">
          <div className="photos-container">
            {photos
              .filter((photo) => PHOTO_DEFINITIONS[photo.id].isVisible)
              .map((photo) => (
                <ImageUploadThumbnail
                  key={photo.id}
                  photo={photo}
                  onChange={onChange}
                  onPreviewClick={setPreviewPhoto}
                  onHelpClick={setHelpModalId}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="button-panel">
        {/* TODO: Change history.goBack() to a navigation forward based on preinspectionsId */}
        <ContinueButton disabled={disabled} onClick={() => history.goBack()}>
          Continuar
        </ContinueButton>
      </div>

      {previewPhoto?.url && (
        <FullScreenPreview
          url={previewPhoto.url}
          onRemove={() => removePhoto(previewPhoto.id, previewPhoto.url)}
          onExit={() => setPreviewPhoto(null)}
        />
      )}
    </div>
  );
};

export default PhotoSectionScreen;
