import React from 'react';

import {
  PhotoId as IVPhotoId,
  getTooltipContents,
} from '@connect-technology/connect-react-components-lib';
import { MediaId } from '../context/photoConstants';

// TODO: Import from connect-react-components-lib.
interface TooltipContent {
  title: string;
  imgUrl?: string;
  paragraph: React.ReactNode;
}

/**
 * The idea of this function is allow us to both re-use the original
 * getTooltipContents from IV, and to extend it with new Photos
 * (like VEHICLE_TOP).
 */
export function getHelpModalContents(
  mediaId: MediaId | null,
): TooltipContent | null {
  if (mediaId === null) {
    return null;
  }

  const ivPhotoId = transformPhotoId(mediaId);
  if (ivPhotoId === null) {
    return null;
  }

  return getTooltipContents(ivPhotoId);
}

export function transformPhotoId(mediaId: MediaId): IVPhotoId | null {
  switch (mediaId) {
    // Vehicle Exterior
    case MediaId.VEHICLE_EXTERIOR_BACK:
      return IVPhotoId.INJURED_VEHICLE_BACK;
    case MediaId.VEHICLE_EXTERIOR_FRONT:
      return IVPhotoId.INJURED_VEHICLE_FRONT;
    case MediaId.VEHICLE_EXTERIOR_LEFT:
      return IVPhotoId.INJURED_VEHICLE_DRIVER_SIDE;
    case MediaId.VEHICLE_EXTERIOR_RIGHT:
      return IVPhotoId.INJURED_VEHICLE_PASSENGER_SIDE;
    case MediaId.VEHICLE_EXTERIOR_PLATE:
      return IVPhotoId.INJURED_VEHICLE_PLATE_PA;
    // Vehicle Interior
    case MediaId.VEHICLE_INTERIOR_VIN:
      return IVPhotoId.INJURED_VEHICLE_VIN;
    case MediaId.VEHICLE_INTERIOR_1:
      return IVPhotoId.INJURED_VEHICLE_INTERIOR_1;
    case MediaId.VEHICLE_INTERIOR_2:
      return IVPhotoId.INJURED_VEHICLE_INTERIOR_2;
    case MediaId.VEHICLE_INTERIOR_ODOMETER:
      return IVPhotoId.INJURED_VEHICLE_ODOMETER;
    // Documents
    case MediaId.DOCUMENTS_CEDULA:
      return IVPhotoId.COUNTERPART_CEDULA;
    case MediaId.DOCUMENTS_DRIVERS_LICENSE:
      return IVPhotoId.COUNTERPART_LICENSE_PA;
    case MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY:
      return IVPhotoId.COUNTERPART_REGISTRO_UNICO;
    default:
      return null;
  }
}
