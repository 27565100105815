import './Onboarding.scss';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useHistory } from 'react-router';
import ContinueButton from '../components/carousel/ContinueButton';
import PATH_ROUTES from '../static/constants/path-routes';
import PageIndicators from '../components/carousel/PageIndicators';
import { PAGES } from '../components/carousel/Pages';
import localforage from 'localforage';

const OnboardingScreen: FunctionComponent = (): JSX.Element => {
  const [nextPage, setNextPage] = useState('');
  const [page, setPage] = useState(0);
  const ref = useRef(null);
  const history = useHistory();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    (async (): Promise<void> => {
      const currentId: any = await localforage.getItem('preId');
      if (currentId) {
        setNextPage(PATH_ROUTES.PRE_TAB_SECTIONS.replace(':id', currentId));
      }
    })();
  }, []);

  return (
    <div className="onboarding">
      <Carousel
        ref={ref}
        responsive={responsive}
        arrows={false}
        draggable={false}
        afterChange={(_, state) => setPage(state.currentSlide)}
      >
        {PAGES}
      </Carousel>

      <PageIndicators count={PAGES.length} selectedIndex={page} />

      <ContinueButton
        onClick={() => {
          if (page < 2) {
            setPage(page + 1);
            (ref.current as any)?.goToSlide?.(page + 1);
          } else {
            history.push(nextPage);
          }
        }}
      >
        {page < 2 ? 'Siguiente' : 'Iniciar'}
      </ContinueButton>
    </div>
  );
};

export default OnboardingScreen;
