import { ActionType } from '../context/actions';
import { MediaId } from '../context/photoConstants';
import { FieldSection } from './form';

export interface IState {
  preInspectionId: string | null;
  preInspectionModel: IPreInspectionModel;
  photos: { [key in MediaId]: Photo };
  formData: FieldSection[] | null;
  formDataInit: FieldSection[] | null;
  extractedData: IExtractedData;
  alertData: IAlertData[] | null;
}

interface IExtractedData {
  plate: string | null;
  vin: string | null;
}
export interface IAlertData {
  code: string | null;
  dataCompare: string | null;
}

export interface IExteriorData {
  id: number;
  zoneName: string;
  s3Url: string;
  webHook: string;
}

export enum CountryCode {
  PRI = 'PRI',
  CRC = 'CRC',
  PAN = 'PAN',
}

export interface Photo {
  id: MediaId;
  url?: string | undefined;
  base64?: string | undefined;
}

export interface SelectOption {
  title: string;
  formSectionId: number;
  idOrder: number;
  isSelected?: boolean;
  hasError?: boolean;
}

export interface SelectedValues {
  label: string;
  value: string;
}

export interface IAppContext {
  state: IState;
  dispatch: DispatchFunction;
}

export type DispatchFunction = (event: IAction) => void;

export interface IAction {
  type: ActionType;
  payload?: any;
}

export interface IPreInspectionModel {
  preInspectionId: number;
  preCompanyId: number;
  preInsuranceType: string;
  preIdType: string;
  preIdInsured: string;
  preName: string;
  preLastName1: string;
  preLastName2: string;
  preBirthDate: string;
  prePhoneNumber: string;
  preEmail: string;
  preProvince: string;
  preCanton: string;
  preDistrict: string;
  preBrokerCode: string;
  preBrokerName: string;
  preCreationDate: string;
  preVehicleBrand: string;
  preVehicleModel: string;
  preVehicleYear: number;
  preVehicleType: string;
  preVehicleVin: string;
  preVehicleMotor: string;
  preVehicleColor: string;
  prePaxNumber: number;
  preTransmission: string;
  preCylinderCapacity: number;
  preInsuranceAmount: number;
  preInitialValidity: string;
  preFinalValidity: string;
  prePlanType: string;
  preCoveragePlan: string;
  preBenefits: string;
  preQuoteNumber: string;
  preVehiclePlate: string;
  preSignature: string;
  preTermsAcceptance: boolean;
  preRedirectURL: string;
  preIsInactive: boolean;
  preEmisionType: number;
}
