import imageCompression from 'browser-image-compression';
import Jimp from 'jimp';
import localforage from 'localforage';
import { ActionType } from '../context/actions';
import {
  MediaId,
  MediaSection,
  PhotoSectionId,
  PHOTO_SECTIONS,
  ValidationError,
} from '../context/photoConstants';
import { CONNECT_SERVICES_CLIENT } from './ConnectServicesClient';
import HeimdallClient from './HeimdallClient';

// Initalize Image Tool (loaded at index.html)
declare global {
  interface Window {
    measureBlur: any;
  }
}

function getFileExtension(type: string) {
  if (type.includes('png')) {
    return '.png';
  } else if (type.includes('jpg')) {
    return '.jpg';
  } else if (type.includes('jpeg')) {
    return '.jpeg';
  } else if (type.includes('mp4')) {
    return '.mp4';
  } else {
    console.warn('Unknown extension', type);
    const guess = type.split('/')[1];

    return '.' + (guess || 'file'); // try our best effort, else fallback to .file
  }
}

export async function uploadPhotoToServer(
  preInspectionId: string,
  mediaId: MediaId,
  event: React.ChangeEvent<HTMLInputElement>,
): Promise<{ awsUrl: string; dataUrl: string; original: File }> {
  if (event.target.files === null) {
    throw Error('Invalid parameter');
  }

  const imageFile = event.target.files[0];
  const compressedFile = await imageCompression(imageFile, {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  });
  const dataUrl = await imageCompression.getDataUrlFromFile(compressedFile);

  const fileExtension = getFileExtension(imageFile.type);
  const filename = `${mediaId}${fileExtension}`;
  let prefix = '';

  Object.keys(MediaSection).forEach((key: any) => {
    if (filename.toUpperCase().indexOf(String(key)) > -1) {
      prefix = MediaSection[key].toString() + '_';
    }
  });

  const response = await CONNECT_SERVICES_CLIENT.uploadMedia(
    preInspectionId,
    prefix + filename,
    fileExtension,
    compressedFile,
  );
  const awsUrl = response.data.data;
  if (!awsUrl) {
    throw new Error('Error uploading photo');
  }

  return { awsUrl, dataUrl: dataUrl, original: imageFile };
}

export async function uploadMediaToServer(
  preInspectionId: string,
  mediaId: MediaId,
  file: File,
): Promise<string> {
  const fileExtension = getFileExtension(file.type);
  const filename = `${mediaId}${fileExtension}`;
  let prefix = '';

  Object.keys(MediaSection).forEach((key: any) => {
    if (filename.toUpperCase().indexOf(String(key)) > -1) {
      prefix = MediaSection[key].toString() + '_';
    }
  });

  const response = await CONNECT_SERVICES_CLIENT.uploadMedia(
    preInspectionId,
    prefix + filename,
    fileExtension,
    file,
  );
  const awsUrl = response.data.data;
  if (!awsUrl) {
    throw new Error('Error uploading photo');
  }

  return awsUrl;
}

export async function uploadBase64ToServer(
  preInspectionId: string,
  base64File: string,
  fileName: string,
  fileExtension: string,
): Promise<string> {
  const response = await CONNECT_SERVICES_CLIENT.uploadMediaBase64(
    preInspectionId,
    fileName,
    fileExtension,
    base64File,
  );
  const awsUrl = response.data.data;
  if (!awsUrl) {
    throw new Error('Error uploading photo');
  }

  return awsUrl;
}

export const isBlurryPhoto = async (fileData: File): Promise<boolean> => {
  return await new Promise((resolve) => {
    //Note: All percentages the scan edges major to 0.95 is tagged a blurry image.
    const toleranceValue = 0.95;

    const readImageFile = (rawFile: File) => {
      return new Promise(function (resolve, reject) {
        if (!rawFile) {
          return reject();
        }

        const reader = new FileReader();
        reader.onload = function (readerEvent) {
          const img = new Image();
          img.onload = function () {
            resolve({
              // NOTE: This is not ImageData object!
              rawFile: rawFile,
              data: img,
              width: img.width,
              height: img.height,
            });
          };
          img.onerror = reject;
          img.src =
            readerEvent &&
            readerEvent.target &&
            readerEvent.target.result !== null &&
            typeof readerEvent.target.result === 'string'
              ? readerEvent.target.result
              : '';
        };
        reader.readAsDataURL(rawFile);
      });
    };

    const done = (img: any) => {
      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      const context = canvas.getContext('2d');

      if (img.data && context) {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img.data, 0, 0);

        const canvasData = context.getImageData(
          0,
          0,
          canvas.width,
          canvas.height,
        );

        const validationResult = window.measureBlur(canvasData);
        canvas.remove();

        if (validationResult && validationResult.avg_edge_width_perc > 0) {
          // eslint-disable-next-line no-console
          console.log(
            'IS_BLURRY::',
            validationResult.avg_edge_width_perc.toFixed(2) > toleranceValue,
            '::EDGE::',
            String(validationResult.avg_edge_width_perc.toFixed(2)),
            '::TOLERANCE::',
            String(toleranceValue),
          );
          resolve(
            validationResult.avg_edge_width_perc.toFixed(2) > toleranceValue,
          );
        } else {
          resolve(false);
        }
      }
    };

    readImageFile(fileData).then(done, console.error);
  });
};

export const dataUrl2Blob = (dataUrl: string): Blob => {
  /* eslint-disable */
  // Yes. This block was pasted from stackoverflow.
  const arr = dataUrl?.split(',');
  const mime = arr![0]?.match(/:(.*?);/)![1];
  const bstr = atob(arr![1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
};

/**
 * Makes requests to Heimdall as needed.
 * @returns validation error (or null if image passed validations) and
 *  a state-mutation redux action as per extraction results.
 **/
export async function runValidations(
  mediaId: MediaId,
  awsUrl: string,
  currentFile: File,
): Promise<[ValidationError | null, any | null]> {
  // Check on-device isBlurry before heavier network-based validations
  const isBlurry = await isBlurryPhoto(currentFile);
  if (isBlurry) {
    return [ValidationError.IS_BLURRY, null];
  }

  const client = new HeimdallClient();
  if (PHOTO_SECTIONS[PhotoSectionId.DOCUMENTS].photoIds.includes(mediaId)) {
    let result = false;
    switch (mediaId) {
      case MediaId.DOCUMENTS_DRIVERS_LICENSE:
      case MediaId.DOCUMENTS_CEDULA:
        result = await client.isIdOrLicense(awsUrl);
        break;
      default:
        result = await client.isDocument(awsUrl);
        break;
    }

    if (result && mediaId === MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY) {
      const currentPreInspectId: string | null = await localforage.getItem(
        'preId',
      );

      if (currentPreInspectId) {
        // Step 1. Convert image to invert / negative color
        const convertedImage = await transformToInvertImage(currentFile);

        // Step 2. Send new image to AWS
        const fileName = currentPreInspectId + '_PROPERTY_REGISTRY_INVERT.jpg';
        const newImageUrl = await uploadBase64ToServer(
          currentPreInspectId,
          convertedImage,
          fileName,
          'jpg',
        );

        // Step 3. Send new URL to Heindall validation to extract data
        const extractedData = await client.extractRUV(newImageUrl);
        console.log('RUV-ExtractedData::', extractedData);

        // Step 4. Delete new image
        await deletePhotoFromServer(newImageUrl);

        // Step 5. Set exract data to state
        return [
          null,
          { type: ActionType.SET_EXTRACTED_RUV, payload: extractedData },
        ];
      }
    }

    return [result ? null : ValidationError.NO_DOCUMENT, null];
  } else if (
    [
      MediaId.VEHICLE_EXTERIOR_FRONT,
      MediaId.VEHICLE_EXTERIOR_RIGHT,
      MediaId.VEHICLE_EXTERIOR_BACK,
      MediaId.VEHICLE_EXTERIOR_LEFT,
    ].includes(mediaId)
  ) {
    // These are only validations to ensure user is following
    //  instructions. So return no redux action.
    const result = await client.isVehicle(awsUrl);

    return [result ? null : ValidationError.NO_VEHICLE, null];
  } else if (mediaId === MediaId.VEHICLE_EXTERIOR_PLATE) {
    const result = await client.extractPlate(awsUrl);
    if (result) {
      return [null, { type: ActionType.SET_EXTRACTED_PLATE, payload: result }];
    } else {
      return [ValidationError.NO_PLATE, null];
    }
  } else if (mediaId === MediaId.VEHICLE_INTERIOR_VIN) {
    const result = await client.extractVin(awsUrl);
    if (result) {
      return [null, { type: ActionType.SET_EXTRACTED_VIN, payload: result }];
    } else {
      // Allow user to pass, since our validation still needs work
      return [null, null];
    }
  } else if (
    mediaId === MediaId.VEHICLE_INTERIOR_1 ||
    mediaId === MediaId.VEHICLE_INTERIOR_2
  ) {
    const result = await client.isVehicleInternal(awsUrl);

    return [result ? null : ValidationError.NO_VEHICLE, null];
  } else if (mediaId === MediaId.VEHICLE_INTERIOR_ODOMETER) {
    const result = await client.isOdometer(awsUrl);

    return [result ? null : ValidationError.NO_ODOMETER, null];
  }
  return [null, []];
}

export async function deletePhotoFromServer(imageUrl: string): Promise<void> {
  await CONNECT_SERVICES_CLIENT.deleteImage(imageUrl);
}

async function transformToInvertImage(currentFile: File): Promise<string> {
  const buffer: any = await currentFile.arrayBuffer();
  const readImage = await Jimp.read(buffer);
  const convert = readImage.quality(30).invert().brightness(-0.09);

  return await convert.getBase64Async(Jimp.MIME_JPEG);
}
