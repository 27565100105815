import './InfoScreen.scss';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { IAppContext } from '../types';
import { AppContext } from '../context/Context';
import ContinueButton from '../components/carousel/ContinueButton';
import PATH_ROUTES from '../static/constants/path-routes';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as WelcomeIcon } from '../assets/imgs/i-welcome.svg';

const WelcomeScreen: FunctionComponent<any> = (): JSX.Element => {
  const history = useHistory();
  const {
    state: { preInspectionModel: modelData },
  } = useContext(AppContext) as IAppContext;

  const filterData = (textData: string): string => {
    return textData && textData.length > 0 ? textData : 'No definido';
  };

  const handleContinue = () => {
    history.push(PATH_ROUTES.PRE_ONBOARDING_SCREEN);
  };

  useEffect(() => {
    // check if there is a preinspection
    if (modelData.preInspectionId <= 0) {
      history.push(PATH_ROUTES.PRE_WELCOME_SCREEN);
    }
  }, []);

  return (
    <div className="info">
      <h2 className="theme-color">¡Bienvenido/a {modelData.preName}!</h2>
      <WelcomeIcon className="icon-welcome theme-svg-icon" />
      <div className="info__summary">
        <p>Por favor revisa la siguiente información:</p>
        <p className="vehicle">{filterData(modelData.preVehicleBrand)}</p>
        <p className="vehicle">{filterData(modelData.preVehicleModel)}</p>
        <p className="vehicle">
          {filterData(String(modelData.preVehicleYear))}
        </p>
        <p className="vehicle">{filterData(modelData.preVehicleColor)}</p>
      </div>
      <div className="footer-info">
        <p>¿Corresponde a tú vehículo?</p>
        <ContinueButton onClick={handleContinue}>
          Sí, deseo continuar
        </ContinueButton>
        <Button
          variant="text"
          onClick={() => {
            history.replace(PATH_ROUTES.PRE_FINISH_SCREEN);
          }}
        >
          No, solicitar ayuda
        </Button>
      </div>
    </div>
  );
};

export default WelcomeScreen;
