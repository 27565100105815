import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import './PageIndicators.scss';

interface PageIndicatorsProps {
  count: number;
  selectedIndex: number;
}
const PageIndicators: FunctionComponent<PageIndicatorsProps> = (props) => {
  return (
    <div className="indicators">
      {new Array(props.count).fill(0).map((_, index) => (
        <div
          key={index}
          className={clsx('indicator', {
            active: props.selectedIndex === index,
          })}
        ></div>
      ))}
    </div>
  );
};

export default PageIndicators;
