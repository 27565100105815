import Axios, { AxiosStatic } from 'axios';
import { IExteriorData } from '../types';

const HEIMDALL_API = process.env.REACT_APP_HEIMDALL_API;

export default class HeimdallClient {
  constructor(private axios: AxiosStatic = Axios) {}

  async isDocument(s3Url: string): Promise<boolean> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/document-existance?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async isVehicle(s3Url: string): Promise<boolean> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/vehicle-existance?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async isIdOrLicense(s3Url: string): Promise<boolean> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/id-license-existance?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async isOdometer(s3Url: string): Promise<boolean> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/odometer-existance?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async isVehicleInternal(s3Url: string): Promise<boolean> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/veh-internal-existance?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async extractPlate(s3Url: string): Promise<string> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/plate-extraction?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async extractVin(s3Url: string): Promise<string> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/vin-extraction?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async extractRUV(s3Url: string): Promise<string> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/ruv-extraction?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async hasLegibleLicense(s3Url: string): Promise<boolean> {
    const response = await this.axios.get(
      `${HEIMDALL_API}/is-legible?s3Url=${s3Url}`,
    );

    return response.data;
  }

  async predictVehicle(objData: IExteriorData): Promise<boolean> {
    const response = await this.axios.post(
      `${HEIMDALL_API}/predict-vehicle`,
      objData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return response.data;
  }
}
