import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import ContinueButton from '../components/carousel/ContinueButton';
import { IAppContext } from '../types';
import { AppContext } from '../context/Context';

import './VideoUploadScreen.scss';
import gifVideoSteps from '../assets/imgs/rotate-vehicle.gif';
import { ReactComponent as BackIcon } from '../assets/imgs/back.svg';
import { ReactComponent as InfoIcon } from '../assets/imgs/i-info.svg';
import { ReactComponent as VideoIcon } from '../assets/imgs/photo-menu/i-360video.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  deletePhotoFromServer,
  uploadMediaToServer,
} from '../helpers/photoUploader';
import { ActionType } from '../context/actions';
import { MediaId } from '../context/photoConstants';
import Modal from '../components/Modal';
import { Alert, Button, Card, CardActionArea, CardMedia } from '@mui/material';
import clsx from 'clsx';
import VideoRecord from '../components/video/VideoRecord';
import ProgressLoading from '../components/common/ProgressLoading';
import { ReactComponent as VideoProcessedImg } from '../assets/imgs/carga_completa.svg';

function formatDuration(duration: number): string {
  return Math.round(duration).toString();
}

/**
 * Taken from: https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
}

// Assuming slow internet of 144Kbps, it'll take ~6 secs per MB
// iOS creates video of size ~1MB per 10 seconds of recording.
const VIDEO_DURATION_LIMIT = 30; // in seconds
const VIDEO_SIZE_LIMIT = 70; // in MB
const BYTES_PER_MB = 1000000;
// Checking if the device support getUserMedia
const videoRecordAllowed =
  !!window.MediaRecorder &&
  !!navigator.mediaDevices &&
  !!navigator.mediaDevices.enumerateDevices &&
  !!navigator.mediaDevices.getUserMedia;
// Check is it is an iOS device
const isiOS =
  !!navigator.userAgent && /ipad|ipod|iphone/i.test(navigator.userAgent);

const loadingMsg = [
  'Esto puede demorar un poco.',
  ' Estamos procesando el video.',
  ' Pronto continuaremos con la inspección.',
];

/**
 * How video uploading works is that onChange we save the file and
 * objectUrl to local state and check validity. If invalid, remove
 * local state. If valid, start uploading and set remote url. UI
 * will display remote url if exist, else local url.
 */
const VideoUploadScreen: FunctionComponent = (): JSX.Element => {
  const { state, dispatch } = useContext(AppContext) as IAppContext;
  const history = useHistory();

  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [objectUrl, setObjectUrl] = useState<string | null>(null);
  const [duration, setDuration] = useState<number | null>(null);
  const [invalidVideo, setInvalidVideo] = useState(true);
  const [loadVideo, setLoadVideo] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showIntroMsg, setShowIntroMsg] = useState(true);
  const [showVideoRecord, setShowVideoRecord] = useState<boolean>(false);
  const [useVideoRecord, setUseVideoRecord] =
    useState<boolean>(videoRecordAllowed);
  const [changeOption, setChangeOption] = useState<boolean>(false);

  const remoteUrl = state.photos[MediaId.VEHICLE_VIDEO].url;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = async (
    event,
  ) => {
    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];
    setVideoFile(file);
    const url = URL.createObjectURL(file); // Remember to "revoke" when done. (URL.revokeObjectURL(objectUrl);)
    setObjectUrl(url); // for displaying locally
  };

  const onLoadedMetadata = () => {
    if (!videoRef.current) {
      return;
    }

    const video = videoRef.current;
    if (video.duration === Infinity) {
      // Theres a special weird case in which we need to force the browser
      //  to re-parse the video so that we get duration.
      // Inspired by  https://www.thecodehubs.com/infinity-audio-video-duration-issue-fixed-using-javascript/
      //  and https://stackoverflow.com/questions/52167815/html5-video-duration-infinity-while-readystate-4.
      video.currentTime = 1e101;
      video.ontimeupdate = function () {
        // eslint-disable-next-line
        this.ontimeupdate = () => {};
        video.currentTime = 0;
        setDuration(video.duration);
      };
    } else {
      setDuration(video.duration);
    }
  };

  // This useEffect is to check validity of video and upload if passes
  useEffect(() => {
    if (
      objectUrl &&
      videoFile &&
      duration &&
      state.preInspectionId &&
      !loading
    ) {
      const size = videoFile.size; // in bytes
      const valid =
        size <= VIDEO_SIZE_LIMIT * BYTES_PER_MB &&
        duration <= VIDEO_DURATION_LIMIT;

      if (valid) {
        setInvalidVideo(false);
        setLoading(true);
        // start uploading... to substitute localUrl for remoteUrl.
        // after done uploading, set remoteUrl and load from there(?).
        uploadMediaToServer(
          state.preInspectionId,
          MediaId.VEHICLE_VIDEO,
          videoFile,
        ).then((url) => {
          setLoading(false);
          dispatch({
            type: ActionType.ADD_PHOTO,
            payload: { id: MediaId.VEHICLE_VIDEO, url: url },
          });
        });
      } else {
        setInvalidVideo(true);
      }
    } else if (!objectUrl && loadVideo) {
      const currentVideoFile = state.photos.VEHICLE_VIDEO ?? null;

      if (currentVideoFile) {
        setObjectUrl(currentVideoFile.url ?? '');

        if (currentVideoFile.url) {
          setInvalidVideo(false);
        }
      }

      setLoadVideo(false);
    }
  }, [objectUrl, videoFile, duration]);

  const retakeVideo = (): void => {
    setVideoFile(null);
    setObjectUrl(null);
    dispatch({
      type: ActionType.ADD_PHOTO,
      payload: { id: MediaId.VEHICLE_VIDEO, url: undefined },
    });
    setInvalidVideo(true);
  };

  const clearInvalidVideo = async () => {
    if (objectUrl) {
      // Do in-memory work first, in case server fails, the user can still proceed
      URL.revokeObjectURL(objectUrl);
      retakeVideo();

      await deletePhotoFromServer(objectUrl);
    }
  };

  const size = videoFile ? videoFile.size : null;

  const recordingComplete = (videoBlob: Blob) => {
    const tmpFile = new File([videoBlob], 'video', {
      type: videoBlob.type,
    });
    const url = URL.createObjectURL(tmpFile); // Remember to "revoke" when done. (URL.revokeObjectURL(objectUrl);)
    setVideoFile(tmpFile);
    setObjectUrl(url); // for displaying locally
    setShowVideoRecord(false);
  };

  const handleChangeOption = () => {
    setShowVideoRecord(false);
    setUseVideoRecord(false);
    setChangeOption(true);
  };

  return (
    <>
      {showVideoRecord && (
        <VideoRecord
          onRecordingComplete={recordingComplete}
          onError={handleChangeOption}
        />
      )}
      {!showVideoRecord && (
        <div className="photo-section-screen video-section-screen">
          {loading && (
            <ProgressLoading messages={loadingMsg} duration={35000} />
          )}
          <div className="container">
            {invalidVideo && size && duration && (
              <Modal>
                <h2>Tamaño del video</h2>
                <div>
                  Video debe ser <i>menos de</i>{' '}
                  <b>{VIDEO_DURATION_LIMIT} segundos</b> y{' '}
                  <b>{VIDEO_SIZE_LIMIT} MB</b> <i>de tamaño</i>.
                  <Alert id="size-alert" severity="error">
                    El video actual dura{' '}
                    <b>{formatDuration(duration)} segundos</b> y tiene un tamaño
                    de <b>{humanFileSize(size, true, 1)}</b>.
                  </Alert>
                </div>
                <ContinueButton onClick={retakeVideo}>Retomar</ContinueButton>
              </Modal>
            )}
            {showIntroMsg && (
              <Modal>
                <h2>¿Cómo realizar el video?</h2>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={gifVideoSteps}
                      alt="video img"
                    />
                  </CardActionArea>
                </Card>
                <div>
                  Camina alrededor del vehículo para realizar el vídeo cómo se
                  muestra en la imagen de arriba.
                </div>
                <ContinueButton
                  onClick={() => {
                    setShowIntroMsg(false);
                  }}
                >
                  Entendido
                </ContinueButton>
              </Modal>
            )}
            <h3 className="lead">
              <BackIcon onClick={history.goBack} />
              <span>Video 360°</span>
              <InfoIcon
                onClick={() => {
                  setShowIntroMsg(true);
                }}
              />
            </h3>
            <div className="photos-scrollable-area">
              <p className="video-instructions">
                Recuerda tener el vehículo limpio y contar con buena
                iluminación.
              </p>
              {!objectUrl && (
                <Alert className="info-alert" severity="info">
                  Video debe durar menos de {VIDEO_DURATION_LIMIT} segundos.
                </Alert>
              )}
              {/* CASE: Meadia recorder and media devices allowed, so we can use react-video-recorder component */}
              {!objectUrl && useVideoRecord && !isiOS && (
                <button
                  className="custom-file-upload"
                  onClick={() => setShowVideoRecord(true)}
                >
                  <VideoIcon className="theme-svg-icon" /> Grabar video 360°
                </button>
              )}
              {/* FALLBACK AND iOS CASE : Meadia recorder and media devices not allowed, so we must use input video */}
              {!objectUrl && (!useVideoRecord || isiOS) && (
                <>
                  <label htmlFor="file-input" className="custom-file-upload">
                    <VideoIcon className="theme-svg-icon" /> Grabar video 360°
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept="video/*"
                    capture="environment"
                    onChange={onChange}
                  />
                </>
              )}
              {!objectUrl && !isiOS && changeOption && (
                <Alert className="info-alert" severity="warning">
                  Oops. Algo salió mal, vuelve a intentarlo.
                </Alert>
              )}
              {/* TODO: How to deal with refresh? Can we load remoteUrl if exist else localUrl */}
              {objectUrl && (
                <>
                  {isiOS && !objectUrl.includes('blob') ? (
                    <>
                      <Alert className="info-alert" severity="success">
                        Tu video ya ha sido cargado al sistema.
                      </Alert>
                      <VideoProcessedImg className="video-processed" />
                    </>
                  ) : (
                    <video
                      ref={videoRef}
                      preload="metadata"
                      width="100%"
                      height="200"
                      controls
                      src={objectUrl || remoteUrl} // prefer local objectUrl if available
                      onLoadedMetadata={onLoadedMetadata}
                    ></video>
                  )}
                  <Button
                    variant="contained"
                    className={clsx('delete-btn button')}
                    onClick={clearInvalidVideo}
                  >
                    <DeleteIcon />
                    Eliminar y retomar
                  </Button>
                </>
              )}
              {size && duration && (
                <Alert severity="info">
                  Video dura <b>{formatDuration(duration)} segundos</b> y tiene
                  un tamaño de <b>{humanFileSize(size, true, 1)}</b>.
                </Alert>
              )}
            </div>
          </div>
          <div className="button-panel">
            <ContinueButton
              disabled={invalidVideo}
              onClick={() => history.goBack()}
            >
              Continuar
            </ContinueButton>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoUploadScreen;
