import { set } from 'lodash';
import { ActionType } from '../context/actions';
import {
  CountryCode,
  DispatchFunction,
  IAlertData,
  IPreInspectionModel,
} from '../types';
import { Dependency, Field, FieldSection } from '../types/form';

export const filterSectionsByDependencies = (
  s: FieldSection,
  sections: FieldSection[],
): boolean => {
  if (!s.visible) {
    return false;
  }

  if (s.dependencyJson) {
    s.dependency = JSON.parse(s.dependencyJson);
  }

  if (!s.dependency) {
    return true;
  }

  const resultDependencies: boolean[] = [];
  s.dependency.forEach((fe: Dependency) => {
    const dFieldName = fe.Name;
    const dSectionId = fe.SectionId;
    const dExpectedValue = fe?.ExpectedValue;

    const fieldValue = sections
      .find((s) => s.formSectionId === dSectionId)
      ?.fields.find((f) => f.name === dFieldName)?.value;
    resultDependencies.push(fieldValue === dExpectedValue);
  });

  return (
    s.dependency.length === resultDependencies.filter((f) => f === true).length
  );
};

export const filterTabs = (
  sectionTabs: FieldSection[],
  sections: FieldSection[],
): FieldSection[] => {
  const tabs = sectionTabs.filter((s) =>
    filterSectionsByDependencies(s, sections),
  );

  return tabs;
};

export const checkDependencyFields = (
  s: FieldSection,
  sectionsInit: FieldSection[] | null,
): Field[] => {
  const checkFields = s.fields.map((f: Field) => {
    if (f.dependency?.name) {
      const field = s.fields.find(
        (f2: Field) =>
          f2.idName === f.dependency?.name &&
          f2.formSectionId === f.dependency?.sectionId,
      );

      if (field) {
        if (field.fieldFormValue !== f.dependency?.expectedValue) {
          f.visible = false;
          f.required = false;
          f.fieldFormValue = '';
        } else {
          const fieldSection = sectionsInit?.find(
            (s2: FieldSection) => s2.formSectionId === f.formSectionId,
          );
          const fieldInit = fieldSection?.fields.find(
            (f3: Field) => f3.idName === f.dependency?.name,
          );
          f.visible = fieldInit?.visible;
          f.required = fieldInit?.required;
        }
      }
    }

    return f;
  });

  return checkFields;
};

export const validationForm = (
  sectionId: number,
  sections: FieldSection[],
  sectionsInit: FieldSection[] | null,
): any => {
  let isValid = false;
  const checkSections = sections.map((s: FieldSection) => {
    // State before revision
    s.fields = checkDependencyFields(s, sectionsInit);
    let fieldsUpdated = s.fields;

    if (s.parentSectionId === sectionId) {
      const totalRequired = s.fields.filter(
        (f: Field) => f.required && f.required === true,
      ).length;

      const totalWithValue = s.fields.filter(
        (f: Field) =>
          f.required &&
          f.required === true &&
          f.fieldFormValue &&
          f.fieldFormValue.length > 0,
      ).length;

      isValid = totalRequired === totalWithValue;

      fieldsUpdated = s.fields.map((f: Field) => {
        f.hasError =
          f.required &&
          f.required === true &&
          (!f.fieldFormValue || f.fieldFormValue.length === 0);
        f.isDisabled = f.hasError ? false : f.isDisabled;

        if (!f.hasError) {
          if (f.endPoint === 'EMAIL' && f.fieldFormValue) {
            const filter = new RegExp(
              '^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$',
            );
            f.hasError = !filter.test(f.fieldFormValue.toLowerCase());
            isValid = !f.hasError;
          }
        }

        return f;
      });
    }

    return { ...s, fields: fieldsUpdated };
  });

  return {
    valid: isValid,
    sections: checkSections,
  };
};

export const setFormFieldValue = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  currentValue: any,
  dispatch: DispatchFunction,
  currentPreInspect: IPreInspectionModel | null,
  currentSections: FieldSection[] | null,
): void => {
  const [fieldName, formSection] = currentValue.name.split('|');
  let fieldValue = currentValue.value
    ? String(currentValue.value).toUpperCase()
    : undefined;

  if (fieldName === 'preSignature') {
    fieldValue = currentValue.value ? currentValue.value : undefined;
  } else if (fieldName === 'preTermsAcceptance') {
    fieldValue = currentValue ? currentValue.value : false;
  }

  const updateSections = currentSections
    ? currentSections.map((section: FieldSection) => {
        if (section.formSectionId === Number(formSection)) {
          const updatedFields = section.fields.map((field: Field) => {
            if (field.idName.toLowerCase() === fieldName.toLowerCase()) {
              field.fieldFormValue = fieldValue;
            }

            return field;
          });
          section.fields = updatedFields;
        }

        return section;
      })
    : [];

  if (currentPreInspect) {
    set(currentPreInspect, fieldName, fieldValue);
  }

  dispatch({
    type: ActionType.SET_PREINSPECTION_MODEL,
    payload: currentPreInspect,
  });

  dispatch({
    type: ActionType.SET_PREINSPECTION_FORM,
    payload: updateSections,
  });
};

export const getCountryFormat = (): any => {
  const countryCode = localStorage.getItem('countryCode');
  const data = {
    dateTimeFormat: 'MM/dd/yyyy',
  };

  switch (countryCode) {
    case CountryCode.PAN:
    case CountryCode.CRC:
      data.dateTimeFormat = 'dd/MM/yyyy';
      break;
    default:
      break;
  }

  return data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mergeObjectData = (source: any, target: any) => {
  Object.keys(source).forEach((key) => {
    try {
      target[key] = source[key];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('The target field is not found.');
      // eslint-disable-next-line no-console
      console.log(error);
    }
  });

  return target;
};

/**
 *
 * @param value The input Value.
 * @param template Template format for the value. Example: ##,####.##
 * @param defaultTag The tag value for merge with the input value. Example: #
 * @param useTemplateLength Return the complete format length or only the input value length.
 * @returns
 */
export const formatValue = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  value: any,
  template: string,
  defaultTag: string,
  useTemplateLength: boolean,
): string => {
  const arrValues: string[] = [];
  const valueStr = String(value);

  let arrTemplate: string[] = [];
  let finalValue = '';
  let idxReference = 1;

  // Process template
  for (let i = 0; i < template.length; i++) {
    const chardata = template.charAt(i);
    if (chardata.length > 0) {
      arrTemplate.push(chardata);
    }
  }

  // Process value
  for (let i = valueStr.length - 1; i >= 0; i--) {
    const chardata = valueStr.charAt(i);
    if (chardata.length > 0) {
      arrValues.push(chardata);
    }
  }

  if (arrValues.length > arrTemplate.filter((f) => f === defaultTag).length) {
    const diffIndex =
      arrValues.length - arrTemplate.filter((f) => f === defaultTag).length;
    arrTemplate = [];
    for (let i = 0; i < diffIndex; i++) {
      template = defaultTag + template;
    }

    // Update template
    for (let i = 0; i < template.length; i++) {
      const chardata = template.charAt(i);
      if (chardata.length > 0) {
        arrTemplate.push(chardata);
      }
    }
  }

  const arrTemplateWithValues: string[] = new Array(arrTemplate.length);
  let lastIndex = 0;

  for (let i = arrTemplate.length - 1; i >= 0; i--) {
    if (arrTemplate[i] === defaultTag) {
      // if has value print the value if not then print the tag
      if (idxReference <= arrValues.length) {
        arrTemplateWithValues[i] = arrValues[idxReference - 1];
        lastIndex = i;
      } else {
        arrTemplateWithValues[i] = defaultTag;
      }
      // Next position for arrValue
      idxReference++;
    } else {
      arrTemplateWithValues[i] = arrTemplate[i];
    }
  }

  for (let i = 0; i <= arrTemplateWithValues.length - 1; i++) {
    if (arrTemplateWithValues[i] !== undefined) {
      if (useTemplateLength) {
        finalValue += arrTemplateWithValues[i];
      } else if (i >= lastIndex) {
        finalValue += arrTemplateWithValues[i];
      }
    }
  }

  return finalValue;
};

export const copyToClipboard = (value: string): void => {
  const el = document.createElement('textarea');
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const CHECKS_ALERTS = [
  { idName: 'preName', property: 'name', code: 'ALERT-RUV-NAME' },
  { idName: 'preLastName1', property: 'lastName1', code: 'ALERT-RUV-LASTNAME' },
  { idName: 'preLastName2', property: 'lastName2', code: 'ALERT-RUV-LASTNAME' },
  { idName: 'preVehiclePlate', property: 'plate', code: 'ALERT-RUV-PLATE' },
  { idName: 'preVehicleType', property: 'type', code: 'ALERT-RUV-TYPE' },
  { idName: 'preVehicleColor', property: 'color', code: 'ALERT-RUV-COLOR' },
  { idName: 'preVehicleBrand', property: 'brand', code: 'ALERT-RUV-BRAND' },
  { idName: 'preVehicleModel', property: 'model', code: 'ALERT-RUV-MODEL' },
  { idName: 'preVehicleYear', property: 'year', code: 'ALERT-RUV-YEAR' },
  { idName: 'preVehicleVin', property: 'vin', code: 'ALERT-RUV-VIN' },
  { idName: 'preVehicleMotor', property: 'motor', code: 'ALERT-RUV-MOTOR' },
  {
    idName: 'preTransmission',
    property: 'transmission',
    code: 'ALERT-RUV-TRANSMISSION',
  },
  {
    idName: 'preInsuranceAmount',
    property: 'insuredAmount',
    code: 'ALERT-RUV-AMOUNT-COMPARE',
  },
  { idName: 'preIsImported', property: null, code: 'ALERT-VEH-IMPORTED' },
];

export const DEPRECIATION_LIST = [
  {
    year: 2,
    percent: 15,
  },
  {
    year: 3,
    percent: 15,
  },
  {
    year: 4,
    percent: 15,
  },
  {
    year: 5,
    percent: 10,
  },
];

export const currencyFormat = (n: number): string => {
  return n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
};

const generalAlertValidations = (
  currentAlertData: IAlertData[] | null,
  field: Field,
  code: string,
): IAlertData[] => {
  const currentValue = field.fieldFormValue;

  switch (code) {
    case 'ALERT-VEH-IMPORTED':
      if (currentAlertData) {
        if (currentValue === '1') {
          currentAlertData.push({
            code: code,
            dataCompare: 'Se ha indicado que el vehículo es importado.',
          });
        } else {
          const existElem = currentAlertData.findIndex(
            (alert: IAlertData) => alert.code === code,
          );
          if (existElem > 0) {
            currentAlertData.splice(existElem, 1);
          }
        }
      }
      break;
    default:
      break;
  }

  return currentAlertData ?? [];
};

const ruvAlertValidations = (
  preinspectData: any,
  inputData: any,
  field: Field,
  code: string,
  property: string,
  idName: string,
): IAlertData[] => {
  const alertData: IAlertData[] = [];
  const currentValue = field.fieldFormValue;
  let extractData = String(inputData[property]);

  if (
    extractData &&
    extractData.toUpperCase() !== 'UNDEFINED' &&
    extractData.toUpperCase() !== 'NULL'
  ) {
    if (
      currentValue &&
      extractData.toUpperCase() === currentValue.toUpperCase()
    ) {
      field.isDisabled =
        currentValue.length > 0 &&
        currentValue !== undefined &&
        currentValue !== 'UNDEFINED';
    } else if (
      currentValue &&
      extractData.toUpperCase() !== currentValue.toUpperCase() &&
      extractData.length > 0
    ) {
      if (idName === 'preInsuranceAmount') {
        // Validate year
        const currentYear = new Date().getFullYear();
        let ruvYear = preinspectData.preVehicleYear;
        if (
          inputData['year'] !== undefined &&
          inputData['year'] !== 'UNDEFINED' &&
          inputData['year'].length > 0
        ) {
          ruvYear = +inputData['year'];
        }

        // Extract depreciation
        const diffYear = currentYear - ruvYear;
        const currentDepreciation = DEPRECIATION_LIST.filter(
          (data: any) =>
            data.year === diffYear || (diffYear > 5 && data.year === 5),
        )[0];

        // Validate amount
        const insuredAmount = parseInt(currentValue.replace(/,/g, ''));
        let ruvAmount = parseInt(extractData.replace(/,/g, ''));

        // Calculate depreciation
        const depreciationAmount =
          currentDepreciation !== undefined
            ? ruvAmount * (currentDepreciation.percent / 100)
            : 0;
        ruvAmount = ruvAmount - depreciationAmount;

        // Apply alerts
        if (insuredAmount > ruvAmount) {
          alertData.push({
            code: code,
            dataCompare: currentValue + ' is greater than ' + extractData,
          });
        }

        if (depreciationAmount > 0) {
          alertData.push({
            code: 'ALERT-RUV-AMOUNT-DEPRECIATION',
            dataCompare: `Se aplicó depreciación a la suma asegurada de un ${currentDepreciation.percent}% correspondiente al año ${diffYear} de fabricación del vehículo.`,
          });
          extractData = currencyFormat(ruvAmount);
        }
      } else {
        alertData.push({
          code: code,
          dataCompare: currentValue + '=>' + extractData,
        });
      }
      field.fieldFormValue = extractData.toUpperCase();
    } else if (
      (!currentValue || currentValue.length === 0) &&
      extractData.length > 0
    ) {
      field.fieldFormValue = extractData.toUpperCase();
    }
  } else {
    // eslint-disable-next-line no-console
    console.log(
      'RUV-INFO :: Extract Data Not Found :: Property => ' + property,
    );
  }

  return alertData;
};

export const getAlertsByData = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  inputData: any,
  preinspectData: IPreInspectionModel,
  currentAlertData: IAlertData[] | null,
  fields: FieldSection[],
): any => {
  const alertData: IAlertData[] = [];
  let updateForm: FieldSection[] = [];

  updateForm = fields.map((section: FieldSection) => {
    if (
      section.level === '2' &&
      section.name !== undefined &&
      ['PRE_GENERAL', 'PRE_VEHICLE'].includes(section.name)
    ) {
      section.fields.forEach((field: Field) => {
        const resultAlert: any = CHECKS_ALERTS.find(
          (f: any) => f.idName === field.idName,
        );

        if (resultAlert) {
          const { idName, property, code } = resultAlert;

          // Validate in the preinspection form data
          if (property === null) {
            generalAlertValidations(currentAlertData, field, code).forEach(
              (alert: IAlertData) => {
                alertData.push(alert);
              },
            );
          } else {
            if (inputData === null || inputData === undefined) {
              // eslint-disable-next-line no-console
              console.log('RUV-INFO :: Alert Empty Model');
            } else {
              // Use for RUV info or when extract data from file
              ruvAlertValidations(
                preinspectData,
                inputData,
                field,
                code,
                property,
                idName,
              ).forEach((alert: IAlertData) => {
                alertData.push(alert);
              });
            }
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('INFO :: Alert not found :: Field => ' + field.idName);
        }
      });
    }

    return section;
  });

  return {
    formData: updateForm,
    extractedData: inputData,
    alertData: alertData,
  };
};
