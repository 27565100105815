import { FC } from 'react';
import './VideoRecord.scss';
import VideoRecorder from 'react-video-recorder';
import renderActions from './renderActions';
import { ReactComponent as Error } from '../../assets/imgs/error.svg';
import dotsLoading from '../../assets/lotties/dots-loading.json';
import { Player } from '@lottiefiles/react-lottie-player';

type VideoRecordProps = {
  onRecordingComplete: (videoBlob: Blob) => void;
  onError: () => void;
};

const CONSTRAINS = {
  audio: true,
  video: {
    facingMode: { exact: 'environment' },
  },
};

const ErrorView = () => {
  return (
    <div className="error-view">
      <Error />
      <p>
        <b>¡Oh no!</b>
      </p>
      <p>¡Oh no! Tu navegador no pudo grabar tu video.</p>
      <p>Reinícialo y vuelve a intentarlo.</p>
    </div>
  );
};

const VideoRecord: FC<VideoRecordProps> = ({
  onRecordingComplete,
  onError,
}) => {
  return (
    <div className="record-container">
      <VideoRecorder
        constrains={CONSTRAINS}
        onRecordingComplete={(videoBlob: Blob) => {
          // Do something with the video...
          onRecordingComplete(videoBlob);
        }}
        isFlipped={false}
        timeLimit={30 * 1000}
        renderDisconnectedView={() => <></>}
        renderLoadingView={() => (
          <>
            <p>Cargando </p>
            <Player
              autoplay
              loop
              src={dotsLoading}
              style={{
                height: 'auto',
                width: 'auto',
                position: 'absolute',
                top: '54%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            ></Player>
          </>
        )}
        renderUnsupportedView={() => (
          <div>Este navegador no puede grabar video</div>
        )}
        renderErrorView={() => <ErrorView />}
        mimeType="video/webm"
        renderActions={renderActions}
        onError={onError}
      />
    </div>
  );
};

export default VideoRecord;
