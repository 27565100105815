import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import './Modal.scss';

interface ModalProps {
  onBackdropClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  children?: React.ReactNode;
  className?: string | undefined;
}
const Modal: FunctionComponent<ModalProps> = ({
  children,
  onBackdropClick,
  className,
}) => {
  const classes = clsx('modal', className);

  return (
    <div className="modal-backdrop" onClick={onBackdropClick}>
      {/* e.stopPropagation because clicking inside modal should not close it */}
      <div className={classes} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
