import { FC, useEffect, useState } from 'react';

/**
 * Taked from https://github.com/fbaiodias/react-video-recorder/blob/master/src/defaults/render-actions.js, and changed according to our needs
 */

const CountDown: FC<{ countdownTime: number }> = ({ countdownTime }) => {
  const [number, setNumber] = useState(countdownTime / 1000);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (number - 1 > 0) {
        setNumber((prevNum) => prevNum - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [number]);

  return <div className="root">{number > 0 ? number : null}</div>;
};

export default CountDown;
