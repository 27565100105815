import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import Button from '@mui/material/Button';
import './ContinueButton.scss';
import { BRAND } from '../../static/constants/brand';

interface ContinueButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  children?: React.ReactNode;
  disabled?: boolean | undefined;
}
const ContinueButton: FunctionComponent<ContinueButtonProps> = ({
  children,
  onClick,
  disabled,
}) => {
  const brand = BRAND;

  return (
    <>
      <Button
        variant="contained"
        className={clsx('continue-btn button', brand, { disabled })}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </Button>
    </>
  );
};

export default ContinueButton;
