import React, { FunctionComponent } from 'react';

import './Congrats.scss';

const CongratsScreen: FunctionComponent = (): JSX.Element => {
  return (
    <div className="congrats">
      <h2>¡Bien Hecho!</h2>
      <p>
        Valida que los datos sean correctos y realiza algún cambio de ser
        necesario.
      </p>
    </div>
  );
};

export default CongratsScreen;
