import localforage from 'localforage';
import { set } from 'lodash';
import { validate as uuidValidate } from 'uuid';
import * as Sentry from '@sentry/browser';
import { ActionType } from '../context/actions';
import { MediaId } from '../context/photoConstants';
import { CONNECT_SERVICES_CLIENT } from '../helpers/ConnectServicesClient';
import HeimdallClient from '../helpers/HeimdallClient';
import { getAlertsByData, mergeObjectData } from '../helpers/Utils';
import { preInspectDefault } from '../static/constants/default';
import {
  DispatchFunction,
  IAlertData,
  IExteriorData,
  IPreInspectionModel,
  IState,
  Photo,
} from '../types';
import { Field, FieldSection } from '../types/form';

export async function getPreInspection(
  uniqueId: string,
): Promise<IPreInspectionModel> {
  try {
    const result = await CONNECT_SERVICES_CLIENT.getPreInspectionByParams(
      uniqueId,
    );

    if (!result.data) {
      return preInspectDefault;
    }

    let currentInspect: IPreInspectionModel = { ...preInspectDefault };
    currentInspect = mergeObjectData(result.data.data, currentInspect);

    return currentInspect;
  } catch (error) {
    Sentry.captureException(error);

    return preInspectDefault;
  }
}

export async function getFormConfiguration(
  uniqueId: string,
): Promise<FieldSection[]> {
  try {
    const result = await CONNECT_SERVICES_CLIENT.getFormByParams(uniqueId);

    if (!result.data || !result.data.data?.sections) {
      return [];
    }

    return result.data.data.sections;
  } catch (error) {
    Sentry.captureException(error);

    return [];
  }
}

export async function syncAlertsWithForm(
  state: IState,
  dispatch: DispatchFunction,
): Promise<void> {
  try {
    if (state && state.formData) {
      const { formData: resultData, alertData: resultAlertData } =
        getAlertsByData(
          state.extractedData,
          state.preInspectionModel,
          state.alertData,
          state.formData,
        );

      if (state.alertData !== null) {
        resultAlertData?.forEach((data: IAlertData) => {
          const findObj = state.alertData?.find(
            (dataFind: any) => dataFind.code === data.code,
          );

          if (findObj === undefined) {
            state.alertData?.push(data);
          }
        });
      }

      dispatch({
        type: ActionType.SET_PREINSPECTION_FORM,
        payload: resultData,
      });

      dispatch({
        type: ActionType.SET_ALERT_MODEL,
        payload: state.alertData,
      });
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function savePreInspection(
  formDataSections: FieldSection[] | null,
  state: IState,
  dispatch: DispatchFunction,
  isFinished?: boolean,
): Promise<void> {
  try {
    if (formDataSections) {
      const preInspectObj: any = {};
      preInspectObj.PreInspectionId = state.preInspectionModel.preInspectionId;
      preInspectObj.ApiKey = process.env.REACT_APP_PARTNER_API_KEY;
      preInspectObj.UniqueId = await localforage.getItem('preId');
      preInspectObj.AcceptConditions = await localforage.getItem(
        'acceptConditions',
      );

      if (isFinished) {
        preInspectObj.Alerts = state.alertData ?? [];
      }

      formDataSections.forEach((fs: FieldSection) => {
        fs.fields.forEach((fv: Field) => {
          if (fv.modelFieldName) {
            let currentValue: any = fv.fieldFormValue;

            if (fv.endPoint) {
              if (
                fv.endPoint.toUpperCase() === 'INTEGER' ||
                fv.endPoint.toUpperCase() === 'DECIMAL'
              ) {
                currentValue = Number(fv.fieldFormValue);
              } else if (fv.endPoint.toUpperCase() === 'BOOLEAN') {
                currentValue =
                  fv.fieldFormValue === '1' ||
                  fv.fieldFormValue === 'ON' ||
                  (typeof fv.fieldFormValue == 'boolean'
                    ? fv.fieldFormValue
                    : false);
              }
            }

            set(preInspectObj, fv.modelFieldName, currentValue);
          }
        });
      });

      if (uuidValidate(preInspectObj.ApiKey)) {
        //TODO: Add debounce implementation for this process
        setTimeout(async () => {
          const result = await CONNECT_SERVICES_CLIENT.addOrEditPreInspect(
            preInspectObj,
          );

          if (result.data) {
            const { response } = result.data.data;
            if (response) {
              // eslint-disable-next-line no-console
              console.log('Info :: Autosave is successfully');

              if (
                isFinished &&
                state !== null &&
                state.preInspectionModel.preInspectionId > 0
              ) {
                const webHookUrl =
                  process.env.REACT_APP_CONNECT_API + '/api/v1/damages';
                const listImages: IExteriorData[] = [
                  {
                    id: state.preInspectionModel.preInspectionId,
                    zoneName: 'FRONTAL',
                    s3Url: state.photos.VEHICLE_EXTERIOR_FRONT.url as string,
                    webHook: webHookUrl,
                  },
                  {
                    id: state.preInspectionModel.preInspectionId,
                    zoneName: 'LATERAL PASAJERO',
                    s3Url: state.photos.VEHICLE_EXTERIOR_RIGHT.url as string,
                    webHook: webHookUrl,
                  },
                  {
                    id: state.preInspectionModel.preInspectionId,
                    zoneName: 'LATERAL CONDUCTOR',
                    s3Url: state.photos.VEHICLE_EXTERIOR_LEFT.url as string,
                    webHook: webHookUrl,
                  },
                  {
                    id: state.preInspectionModel.preInspectionId,
                    zoneName: 'TRASERO',
                    s3Url: state.photos.VEHICLE_EXTERIOR_BACK.url as string,
                    webHook: webHookUrl,
                  },
                ];

                const client = new HeimdallClient();
                listImages.forEach((ed: IExteriorData) => {
                  setTimeout(async () => {
                    await client.predictVehicle(ed);
                  }, 3000);
                });

                // eslint-disable-next-line no-console
                console.log(
                  'Info :: Send the exterior vehicle photos for analysis.',
                );
              } else if (+response > 0) {
                dispatch({
                  type: ActionType.SET_PREINSPECTION_ID,
                  payload: +response,
                });
              } else {
                // eslint-disable-next-line no-console
                console.log(
                  'Alert :: State model is undefined or preInspectionId is not a valid value.',
                );
              }
            }
          }
        }, 1500);
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getPreInspectMedia(uniqueId: string): Promise<any> {
  try {
    const result = await CONNECT_SERVICES_CLIENT.getMedia(uniqueId);
    const arrMedia: { [key in MediaId]?: Photo } = {};
    const mediaTemplate = (id: MediaId): any => {
      return { id: id, url: undefined };
    };

    if (!result.data) {
      return [];
    }

    const arrMediaData: any[] = Array.from(result.data.data);

    Object.values(MediaId).forEach((value) => {
      const currentMedia = arrMediaData.find(
        (m: any) => m.url !== undefined && String(m.url).indexOf(value) > -1,
      );
      if (currentMedia !== undefined) {
        const mediaData: Photo = {
          id: value,
          url: currentMedia.url,
        };

        arrMedia[value] = mediaData;
      } else {
        arrMedia[value] = mediaTemplate(value);
      }
    });

    return arrMedia as { [key in MediaId]: Photo };
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function requestPreInspectionFinish(
  preinspectId: number,
): Promise<string> {
  try {
    const result = await CONNECT_SERVICES_CLIENT.getPreInspectionFinishStatus(
      preinspectId,
    );

    return result.data;
  } catch (error) {
    Sentry.captureException(error);

    return 'EXPIRE';
  }
}

export async function sharedPreInspection(
  preinspectId: number,
  companyId: number,
  type: string,
  email: string,
  link: string,
): Promise<string> {
  try {
    const result = await CONNECT_SERVICES_CLIENT.sharedPreInspection({
      Id: preinspectId,
      CompanyId: companyId,
      Type: type.toUpperCase(),
      Email: email,
      Link: link,
    });

    return result.data;
  } catch (error) {
    Sentry.captureException(error);

    return 'EMAIL_NOT_SEND';
  }
}
