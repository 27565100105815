import { ReactComponent as Documents } from '../../assets/imgs/documents.svg';
import { ReactComponent as Pictures } from '../../assets/imgs/pictures.svg';
import { ReactComponent as Time } from '../../assets/imgs/time.svg';

import './Pages.scss';

export const PAGES = [
  <div className="page" key="1">
    <div className="text-container">
      <h2>Documentos</h2>
      <p>Tener a mano los siguientes documentos:</p>
      <ul>
        <li>Registro de propiedad vehicular</li>
        <li>Cédula de identidad</li>
        <li>Licencia de conducir</li>
      </ul>
    </div>
    <div className="image-container">
      <Documents />
    </div>
  </div>,

  <div className="page" key="2">
    <div className="text-container">
      <h2>Tiempo</h2>
      <p>
        Este servicio se trata de un proceso grabado en tiempo real, fechado,
        geolocalizado y cronometrado.
      </p>
    </div>
    <div className="image-container">
      <Time />
    </div>
  </div>,

  <div className="page" key="3">
    <div className="text-container">
      <h2>Fotografías</h2>
      <p>Es necesario que la cámara funcione perfectamente.</p>
    </div>
    <div className="image-container">
      <Pictures />
    </div>
  </div>,
];
