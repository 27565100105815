import clsx from 'clsx';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { SelectOption } from '../../../types';
import './styles.scss';

interface TabStepsProps {
  steps: SelectOption[];
  activeStep: SelectOption;
  onSelectedTab: (value: SelectOption) => void;
}

const TabSteps: FunctionComponent<TabStepsProps> = ({
  steps,
  activeStep,
  onSelectedTab,
}): JSX.Element => {
  return (
    <div className="steps">
      {steps.map((step) => (
        <Step
          key={step.formSectionId}
          step={step}
          onSelectedTab={onSelectedTab}
          activeStep={activeStep}
        />
      ))}
    </div>
  );
};

interface StepProps {
  step: SelectOption;
  activeStep: SelectOption;
  onSelectedTab: (value: SelectOption) => void;
}

const Step: FunctionComponent<StepProps> = ({
  step,
  activeStep,
  onSelectedTab,
}) => {
  const ref = useRef<any>(null);
  const [selected, setSelected] = useState(
    step.formSectionId === activeStep.formSectionId,
  );

  useEffect(() => {
    const isActive = step.formSectionId === activeStep.formSectionId;

    if (isActive && ref?.current) {
      const center =
        (ref.current.offsetLeft || 0) -
        window?.screen?.width / 2 +
        (ref.current.offsetWidth || 0) / 2;
      if (ref.current.parentElement) {
        ref.current.parentElement.scroll(center, 0);
      }
    }

    setSelected(isActive);
  }, [activeStep, step]);

  return (
    <div
      ref={ref}
      className={clsx('theme-color', 'steps__tab', {
        'steps__tab--selected': selected,
        'steps__tab--error': step.hasError,
      })}
      onClick={() => onSelectedTab(step)}
    >
      {step.title}
    </div>
  );
};

export default TabSteps;
