import './InactiveScreen.scss';
import React, { FunctionComponent } from 'react';
import { BRAND, LOGOS } from '../static/constants/brand';
import noImage from '../assets/imgs/no-image.png';

const InactiveScreen: FunctionComponent = (): JSX.Element => {
  return (
    <div className="inactive">
      <picture>
        <source srcSet={LOGOS[BRAND]} />
        <img src={noImage} alt="Application Logo" />
      </picture>
      <h2>
        Este enlace se encuentra <b>INACTIVO</b>
      </h2>
      <h3>
        Powered by <span>Connect Assistance</span>
      </h3>
    </div>
  );
};

export default InactiveScreen;
