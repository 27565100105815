import { getAlertsByData } from '../helpers/Utils';
import {
  extractedDataDefault,
  preInspectDefault,
} from '../static/constants/default';
import { IAction, IState, Photo } from '../types';
import { Field, FieldSection } from '../types/form';
import { ActionType } from './actions';
import { MediaId } from './photoConstants';

function initialPhotoState(id: MediaId) {
  return { id: id, url: undefined };
}

// Change to uppercase, remove whitespace
export function normalizePlate(plate: string): string {
  return plate
    .toUpperCase()
    .replace(/[^a-zA-Z0-9]/g, '')
    .replace(/\s/g, '')
    .trim();
}

/** Creates a MediaId => { id } mapping */
function initialPhotosState() {
  const state: { [key in MediaId]?: Photo } = {};
  (Object.keys(MediaId) as MediaId[]).forEach((mediaId) => {
    state[mediaId] = initialPhotoState(mediaId);
  });

  return state as { [key in MediaId]: Photo };
}

export const initialState: IState = {
  preInspectionId: null,
  preInspectionModel: preInspectDefault,
  photos: initialPhotosState(),
  formData: null,
  formDataInit: null,
  extractedData: extractedDataDefault, // data extracted from images
  alertData: null,
};

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case ActionType.SET_PREINSPECTION_ID:
      return {
        ...state,
        preInspectionModel: {
          ...state.preInspectionModel,
          preInspectionId: action.payload,
        },
      };
    case ActionType.SET_PREINSPECTION_UNIQUEID:
      return { ...state, preInspectionId: action.payload };
    case ActionType.SET_PREINSPECTION_MODEL:
      return { ...state, preInspectionModel: action.payload };
    case ActionType.SET_PREINSPECTION_FORM_INIT:
      return { ...state, formDataInit: action.payload };
    case ActionType.SET_PREINSPECTION_FORM:
      return { ...state, formData: action.payload };
    case ActionType.SET_PREINSPECTION_MEDIA:
      return { ...state, photos: action.payload };
    case ActionType.ADD_PHOTO: {
      const photos = { ...state.photos };
      photos[action.payload.id as MediaId] = {
        ...photos[action.payload.id as MediaId],
        url: action.payload.url,
      };

      return { ...state, photos };
    }
    case ActionType.REMOVE_PHOTO: {
      const photos = { ...state.photos };
      photos[action.payload.id as MediaId] = initialPhotoState(
        action.payload.id,
      );

      return { ...state, photos };
    }
    case ActionType.SET_EXTRACTED_PLATE: {
      const plate = normalizePlate(action.payload);

      return {
        ...state,
        preInspectionModel: {
          ...state.preInspectionModel,
          preVehiclePlate: plate,
        },
        extractedData: { ...state.extractedData, plate: plate },
      };
    }
    case ActionType.SET_EXTRACTED_VIN:
      return {
        ...state,
        preInspectionModel: {
          ...state.preInspectionModel,
          preVehicleVin: action.payload,
        },
        extractedData: { ...state.extractedData, vin: action.payload },
      };
    case ActionType.SET_EXTRACTED_RUV: {
      let vehicleData = action.payload;

      if (!state.formData) {
        return {
          ...state,
        };
      }

      const { formData, alertData } = getAlertsByData(
        vehicleData,
        state.preInspectionModel,
        state.alertData,
        state.formData,
      );

      // Set Owner attributes
      vehicleData = {
        ...action.payload,
        name: '',
        lastName1: '',
        lastName2: '',
      };

      // Define the current Owner Name for the last analisys of the process
      state.formData.forEach((section: FieldSection) => {
        if (
          section.level === '2' &&
          section.name !== undefined &&
          ['PRE_GENERAL'].includes(section.name)
        ) {
          section.fields.forEach((field: Field) => {
            if (
              vehicleData !== null &&
              vehicleData !== undefined &&
              ['preName', 'preLastName1', 'preLastName2'].includes(field.idName)
            ) {
              switch (field.idName) {
                case 'preName':
                  vehicleData.name = field.fieldFormValue;
                  break;
                case 'preLastName1':
                  vehicleData.lastName1 = field.fieldFormValue;
                  break;
                case 'preLastName2':
                  vehicleData.lastName2 = field.fieldFormValue;
                  break;
                default:
                  break;
              }
            }
          });
        }
      });

      return {
        ...state,
        formData: formData,
        extractedData: vehicleData,
        alertData: alertData,
      };
    }
    case ActionType.SET_ALERT_MODEL:
      return { ...state, alertData: action.payload };
  }
};

export default reducer;
