// Duplicate while I figure out uses in other places -ESantos
export interface FormField {
  name: string;
  label: string;
  value: any;
  nameOption?: string;
  showOptions?: boolean;
  options?: string[];
  type?: string;
  inputValue?: string;
  viewLength: any;
  contentText: string;
  formSectionId: number;
  isDisabled: boolean;
}

export interface Field {
  formFieldId: number;
  idOrder: number;
  idName: any;
  labelName: string;
  fieldFormType: string;
  fieldFormValue?: string;
  columns: number;
  lines: number;
  name?: string;
  label?: string;
  value?: any;
  originalValue?: any;
  nameOption?: string;
  showOptions?: boolean;
  dropDownOptions?: string;
  radioButtonOptions?: string;
  checkBoxOptions?: string;
  mask?: string;
  cssClass?: string;
  endPoint?: string;
  options?: any[];
  type?: string;
  modelFieldName?: string;
  inputValue?: string | number;
  viewLenght?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 11 | 12;
  fieldDependencyId?: number;
  dependency?: { sectionId: number; name: string; expectedValue: any };
  dependencyJson?: string;
  contentText?: string;
  formSectionId?: number;
  fieldPath?: number;
  isDisabled?: boolean;
  dataType?: string;
  enabled?: boolean;
  visible?: boolean;
  required?: boolean;
  sendFieldType?: string;
  hasError?: boolean;
  inactiveBlur?: boolean;
}

export interface FieldSection {
  formSectionId: number;
  title: string;
  fields: Field[];
  idName?: any;
  idOrder: number;
  parentSectionId?: number;
  dependency?: Dependency[];
  dependencyJson?: string;
  level?: string;
  name?: string;
  visible?: boolean;
  required?: boolean;
  hasError?: boolean;
}

export interface Dependency {
  SectionId: number;
  Name: string;
  ExpectedValue: any;
}

export enum TabDataSections {
  PRE_PHOTOS = 'PRE_PHOTOS',
  PRE_GENERAL = 'PRE_GENERAL',
  PRE_VEHICLE = 'PRE_VEHICLE',
  PRE_TERMS = 'PRE_TERMS',
  PRE_RESUME = 'PRE_RESUME',
}

export enum FieldTypes {
  TEXT = 'text',
  FILLED_TEXT = 'filledtext',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  CHECKBOX_LIST = 'checkboxlist',
  RADIOBUTTON = 'radiobutton',
  DISCLAIMER = 'disclaimer',
  DROPDOWN = 'dropdown',
  TEXTAREA = 'textarea',
  SIGNATURE = 'signature',
  STYLIZED_RADIOBUTTONS = 'stylizedradiobuttons',
  SWITCH = 'switch',
  DATE = 'date',
  TIME = 'time',
  DIAGRAM = 'diagram',
  HTMLTEXT = 'htmltext',
  MAP = 'map',
  DAMAGES_DIAGRAM = 'damagesdiagram',
  DYNAMIC_DROPDOWN = 'dynamicdropdown',
  BUTTON = 'button',
  CHIPSTATUS = 'chipstatus',
}

export enum ValueTypes {
  INTEGER = 'integer',
  STRING = 'string',
  BOOLEAN = 'boolean',
}
