//App Routes Constants
export default {
  PRE_SPLASH_SCREEN: '/:id',
  PRE_INACTIVE_SCREEN: '/inactive',
  PRE_WELCOME_SCREEN: '/screen/welcome',
  PRE_INFO_SCREEN: '/screen/info',
  PRE_ONBOARDING_SCREEN: '/screen/onboarding',
  PRE_TAB_SECTIONS: '/preinspections/:id/sections/:sectionId',
  PRE_PHOTO_SECTION: '/photo-sections/:id',
  PRE_VIDEO_SECTION: '/photo-sections/video',
  PRE_CONGRATS_SCREEN: '/screen/congrats',
  PRE_FINISH_SCREEN: '/screen/finished',
  PRE_SHARED_SCREEN: '/preinspections/shared/:id',
};
