import CountDown from './actions/Countdown';
import VideoTimer from './actions/VideoTimer';
import './renderActions.scss';
import { ReactComponent as CameraIcon } from '../../assets/imgs/camera.svg';

/**
 * Taked from https://github.com/fbaiodias/react-video-recorder/blob/master/src/defaults/render-actions.js, and changed according to our needs
 */

interface renderActionsParams {
  t: (text: string) => string;
  isVideoInputSupported: boolean;
  isInlineRecordingSupported: boolean;
  thereWasAnError: boolean;
  isRecording: boolean;
  isCameraOn: boolean;
  streamIsReady: boolean;
  isConnecting: boolean;
  isRunningCountdown: boolean;
  countdownTime: number;
  timeLimit: number;
  isReplayingVideo: boolean;
  useVideoInput: boolean;

  onTurnOnCamera: () => void;
  onOpenVideoInput: () => void;
  onStartRecording: () => void;
  onStopRecording: () => void;
  onStopReplaying: () => void;
}

const renderActions = ({
  t,
  isVideoInputSupported,
  isInlineRecordingSupported,
  thereWasAnError,
  isRecording,
  isCameraOn,
  streamIsReady,
  isConnecting,
  isRunningCountdown,
  isReplayingVideo,
  countdownTime,
  timeLimit,
  useVideoInput,

  onTurnOnCamera,
  onOpenVideoInput,
  onStartRecording,
  onStopRecording,
  onStopReplaying,
}: renderActionsParams): JSX.Element => {
  const renderContent = () => {
    const shouldUseVideoInput =
      !isInlineRecordingSupported && isVideoInputSupported;

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }

    if (isReplayingVideo) {
      return (
        <button
          className="action-button"
          onClick={onStopReplaying}
          data-qa="start-replaying"
        >
          {t('Usa otro video')}
        </button>
      );
    }

    if (isRecording) {
      return (
        <div className="button-border stop-wrapper">
          <button
            className="stop-button"
            onClick={onStopRecording}
            data-qa="stop-recording"
          />
        </div>
      );
    }

    if (isCameraOn && streamIsReady) {
      return (
        <div className="rec-wrapper">
          <div className="rec-text">Presiona el botón rojo para iniciar</div>
          <div className="button-border">
            <button
              className="rec-button"
              type="button"
              onClick={onStartRecording}
              data-qa="start-recording"
            />
          </div>
        </div>
      );
    }

    if (useVideoInput) {
      return (
        <button
          className="action-button"
          type="button"
          onClick={onOpenVideoInput}
          data-qa="open-input"
        >
          {t('Subir un video')}
        </button>
      );
    }

    return shouldUseVideoInput ? (
      <button
        className="action-button"
        type="button"
        onClick={onOpenVideoInput}
        data-qa="open-input"
      >
        {t('Grabar un video')}
      </button>
    ) : (
      <button
        className="action-button"
        type="button"
        onClick={onTurnOnCamera}
        data-qa="turn-on-camera"
      >
        <CameraIcon />
        {t('Activar cámara')}
      </button>
    );
  };

  return (
    <div className="render-actions">
      {isRecording && <VideoTimer timeLimit={timeLimit} />}
      {isRunningCountdown && <CountDown countdownTime={countdownTime} />}
      <div className="actions-container">{renderContent()}</div>
    </div>
  );
};

export default renderActions;
