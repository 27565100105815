/**
 * This data is fixed, so we don't include it and mantain it in the
 * global state.
 */
// Menu Section Icons
import { ReactComponent as VideoIcon } from '../assets/imgs/photo-menu/i-360video.svg';
import { ReactComponent as DocumentIcon } from '../assets/imgs/photo-menu/i-documento.svg';
import { ReactComponent as ExteriorIcon } from '../assets/imgs/photo-menu/i-exterior.svg';
import { ReactComponent as InteriorIcon } from '../assets/imgs/photo-menu/i-interior.svg';

// Exterior Placeholders
import { ReactComponent as Plate } from '../assets/imgs/photo-placeholders/exterior/plate.svg';
import { ReactComponent as Front } from '../assets/imgs/photo-placeholders/exterior/front.svg';
import { ReactComponent as Left } from '../assets/imgs/photo-placeholders/exterior/left.svg';
import { ReactComponent as Right } from '../assets/imgs/photo-placeholders/exterior/right.svg';
import { ReactComponent as Back } from '../assets/imgs/photo-placeholders/exterior/back.svg';

// Documents Placeholders
import { ReactComponent as Scan } from '../assets/imgs/photo-placeholders/documents/i-scan.svg';

// Interior Placeholders
import { ReactComponent as InteriorPlaceholder } from '../assets/imgs/photo-placeholders/interior/i-interior-1.svg';
import { ReactComponent as Vin } from '../assets/imgs/photo-placeholders/interior/i-vin.svg';
import { ReactComponent as Speedometer } from '../assets/imgs/photo-placeholders/interior/i-speedometer.svg';

import { ReactElement } from 'react';

// ===== Media type files
export enum MediaSection {
  VEHICLE_EXTERIOR = 40,
  VEHICLE_INTERIOR = 41,
  VEHICLE_VIDEO = 42,
  DOCUMENTS = 43,
}

export enum MediaId {
  VEHICLE_EXTERIOR_PLATE = 'VEHICLE_EXTERIOR_PLATE',
  VEHICLE_EXTERIOR_FRONT = 'VEHICLE_EXTERIOR_FRONT',
  VEHICLE_EXTERIOR_RIGHT = 'VEHICLE_EXTERIOR_RIGHT',
  VEHICLE_EXTERIOR_BACK = 'VEHICLE_EXTERIOR_BACK',
  VEHICLE_EXTERIOR_LEFT = 'VEHICLE_EXTERIOR_LEFT',

  VEHICLE_INTERIOR_ODOMETER = 'VEHICLE_INTERIOR_ODOMETER',
  VEHICLE_INTERIOR_VIN = 'VEHICLE_INTERIOR_VIN',
  VEHICLE_INTERIOR_1 = 'VEHICLE_INTERIOR_1',
  VEHICLE_INTERIOR_2 = 'VEHICLE_INTERIOR_2',

  VEHICLE_VIDEO = 'VEHICLE_VIDEO',

  DOCUMENTS_VEHICLE_PROPERTY_REGISTRY = 'DOCUMENTS_VEHICLE_PROPERTY_REGISTRY',
  DOCUMENTS_CEDULA = 'DOCUMENTS_CEDULA',
  DOCUMENTS_DRIVERS_LICENSE = 'DOCUMENTS_DRIVERS_LICENSE',
}

export enum ValidationError {
  IS_BLURRY = 'IS_BLURRY',
  NO_DOCUMENT = 'NO_DOCUMENT',
  NO_VEHICLE = 'NO_VEHICLE',
  NO_PLATE = 'NO_PLATE',
  NO_VIN = 'NO_VIN',
  NO_ODOMETER = 'NO_ODOMETER',
}

export interface PhotoDefinition {
  placeholder: any;
  title: string;
  shortTitle: string;
  exampleImageUrl: string;
  isVisible: boolean;
}

export const PHOTO_DEFINITIONS: { [key in MediaId]: PhotoDefinition } = {
  [MediaId.VEHICLE_EXTERIOR_PLATE]: {
    placeholder: <Plate className="theme-svg-icon" />,
    title: 'Placa',
    shortTitle: 'Placa',
    exampleImageUrl: '',
    isVisible: true,
  },
  [MediaId.VEHICLE_EXTERIOR_FRONT]: {
    placeholder: <Front className="theme-svg-icon" height={50} />,
    title: 'Frontal',
    shortTitle: 'Frontal',
    exampleImageUrl: '',
    isVisible: true,
  },
  [MediaId.VEHICLE_EXTERIOR_RIGHT]: {
    placeholder: <Right className="theme-svg-icon" />,
    title: 'Lateral derecho',
    shortTitle: 'Lateral derecho',
    exampleImageUrl: '',
    isVisible: true,
  },
  [MediaId.VEHICLE_EXTERIOR_BACK]: {
    placeholder: <Back className="theme-svg-icon" />,
    title: 'Trasera',
    shortTitle: 'Trasera',
    exampleImageUrl: '',
    isVisible: true,
  },
  [MediaId.VEHICLE_EXTERIOR_LEFT]: {
    placeholder: <Left className="theme-svg-icon" />,
    title: 'Lateral izquierdo',
    shortTitle: 'Lateral izquierdo',
    exampleImageUrl: '',
    isVisible: true,
  },
  [MediaId.VEHICLE_INTERIOR_ODOMETER]: {
    placeholder: <Speedometer className="theme-svg-icon" />,
    title: 'Odómetro',
    shortTitle: 'Odómetro',
    exampleImageUrl: '',
    isVisible: true,
  },
  [MediaId.VEHICLE_INTERIOR_VIN]: {
    placeholder: <Vin className="theme-svg-icon" />,
    title: 'VIN (Número de Chasis)',
    shortTitle: 'VIN',
    exampleImageUrl: '',
    isVisible: true,
  },
  [MediaId.VEHICLE_INTERIOR_1]: {
    placeholder: <InteriorPlaceholder className="theme-svg-icon" />,
    title: 'Interior 1',
    shortTitle: 'Interior 1',
    exampleImageUrl: '',
    isVisible: true,
  },
  [MediaId.VEHICLE_INTERIOR_2]: {
    placeholder: <InteriorPlaceholder className="theme-svg-icon" />,
    title: 'Interior 2',
    shortTitle: 'Interior 2',
    exampleImageUrl: '',
    isVisible: true,
  },

  [MediaId.VEHICLE_VIDEO]: {
    placeholder: <VideoIcon className="theme-svg-icon" />,
    title: 'Video',
    shortTitle: 'Video',
    exampleImageUrl: '',
    isVisible: true,
  },

  [MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY]: {
    placeholder: <Scan className="theme-svg-icon" />,
    title: 'Registro de propiedad vehicular',
    shortTitle: 'RUV',
    exampleImageUrl: '',
    isVisible: true,
  },
  [MediaId.DOCUMENTS_CEDULA]: {
    placeholder: <Scan className="theme-svg-icon" />,
    title: 'Cédula de identidad',
    shortTitle: 'Cédula',
    exampleImageUrl: '',
    isVisible: true,
  },
  [MediaId.DOCUMENTS_DRIVERS_LICENSE]: {
    placeholder: <Scan className="theme-svg-icon" />,
    title: 'Licencia de conducir',
    shortTitle: 'Licencia',
    exampleImageUrl: '',
    isVisible: true,
  },
};

// ===== Photo Sections
export enum PhotoSectionId {
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  VIDEO = 'VIDEO',
  DOCUMENTS = 'DOCUMENTS',
}

export function photoSectionIdFromUrlSlug(id: string): PhotoSectionId {
  return id.toUpperCase() as PhotoSectionId;
}

interface PhotoSectionDefinition {
  icon: ReactElement;
  title: string;
  photoIds: MediaId[];
  helpModal: ReactElement;
}

export const PHOTO_SECTIONS: {
  [key in PhotoSectionId]: PhotoSectionDefinition;
} = {
  [PhotoSectionId.EXTERIOR]: {
    icon: <ExteriorIcon className="theme-svg-icon" width={30} height={30} />,
    title: 'Exterior del vehículo',
    photoIds: [
      MediaId.VEHICLE_EXTERIOR_PLATE,
      MediaId.VEHICLE_EXTERIOR_FRONT,
      MediaId.VEHICLE_EXTERIOR_RIGHT,
      MediaId.VEHICLE_EXTERIOR_BACK,
      MediaId.VEHICLE_EXTERIOR_LEFT,
    ],
    helpModal: (
      <div>
        <p>
          Toma las siguientes consideraciones a la hora de tomar las
          fotografías:
        </p>
        <ul>
          <li>
            Contar con el vehículo limpio <b>(sin barro ni polvo)</b>.
          </li>
          <li>Debe verse el vehículo completo.</li>
          <li>El lugar donde realizas la inspección debe estar iluminado.</li>
        </ul>
      </div>
    ),
  },
  [PhotoSectionId.INTERIOR]: {
    icon: <InteriorIcon className="theme-svg-icon" width={30} height={30} />,
    title: 'Interior del vehículo',
    photoIds: [
      MediaId.VEHICLE_INTERIOR_ODOMETER,
      MediaId.VEHICLE_INTERIOR_VIN,
      MediaId.VEHICLE_INTERIOR_1,
      MediaId.VEHICLE_INTERIOR_2,
    ],
    helpModal: (
      <div>
        <p>
          Toma las siguientes consideraciones a la hora de tomar las
          fotografías:
        </p>
        <ul>
          <li>Buena ilumincación.</li>
        </ul>
      </div>
    ),
  },
  [PhotoSectionId.DOCUMENTS]: {
    icon: <DocumentIcon className="theme-svg-icon" width={30} height={30} />,
    title: 'Documentos',
    photoIds: [
      MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY,
      MediaId.DOCUMENTS_CEDULA,
      MediaId.DOCUMENTS_DRIVERS_LICENSE,
    ],
    helpModal: (
      <div>
        <p>
          Toma las siguientes consideraciones a la hora de tomar las
          fotografías:
        </p>
        <ul>
          <li>Colocar el documento sobre una superficie plana.</li>
          <li>
            Contar con buena iluminación o habilita el flash del dispositivo.
          </li>
          <li>
            Tomar la foto lo más cerca posible{' '}
            <b>(debe verse el documento completo)</b>.
          </li>
          <li>La información en el documento debe ser legible</li>
        </ul>
      </div>
    ),
  },
  [PhotoSectionId.VIDEO]: {
    icon: <VideoIcon className="theme-svg-icon" width={30} height={30} />,
    title: 'Video 360°',
    photoIds: [MediaId.VEHICLE_VIDEO],
    helpModal: (
      <div>
        <p>Toma las siguientes consideraciones a la hora de tomar el video:</p>
        <ul>
          <li>
            Contar con el vehículo limpio <b>(sin barro ni polvo)</b>.
          </li>
          <li>Debe verse el vehículo completo.</li>
          <li>El lugar donde realizas la inspección debe estar iluminado.</li>
        </ul>
      </div>
    ),
  },
};
