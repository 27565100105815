import React, { FC, useEffect, useRef, useState } from 'react';
import { ReactComponent as LoadingIcon } from '../../assets/imgs/loading.svg';
import './Loading.scss';

interface ProgressLoadingProps {
  messages?: string[];
  duration?: number;
}

const DEFAULT_MESSAGES = [
  'Un momento por favor, estamos procesando la fotografía.',
  'Esto puede demorar un poco, un momento por favor.',
  'Estamos procesando la fotografía, pronto continuaremos con la inspección.',
];

const ProgressLoading: FC<ProgressLoadingProps> = ({
  messages = DEFAULT_MESSAGES,
  duration = 15000,
}) => {
  const [currentMessage, setCurrentMessage] = useState<string>(
    messages[0] || 'Cargando información',
  );
  const messagePos = useRef<number>(1);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (messages.length > 0 && messagePos.current < messages.length) {
      const msgTime = Math.ceil(duration / messages.length);
      timeout = setTimeout(() => {
        setCurrentMessage(messages[messagePos.current]);
        messagePos.current = messagePos.current + 1;
      }, msgTime);
    }

    return () => {
      if (messages.length > 0) {
        clearTimeout(timeout);
      }
    };
  }, [currentMessage]);

  return (
    <div className="loading">
      <LoadingIcon data-testid="loading-icon" />
      <h2>{currentMessage}</h2>
    </div>
  );
};

export default ProgressLoading;
