import { Checkbox, FormControlLabel } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { Field } from '../../types/form';

interface Props {
  label: string;
  field: Field;
  defaultValue: string | undefined;
  isDisabled: boolean | undefined;
  onChange: (event: any) => any;
  onBlur: () => void;
}

const CheckBoxData = ({
  label,
  field,
  defaultValue,
  isDisabled,
  onChange,
  onBlur,
}: Props): ReactElement => {
  const [checkValue, setCheckValue] = useState<boolean>(
    defaultValue !== undefined &&
      (defaultValue === 'True' ||
        defaultValue === 'ON' ||
        defaultValue === '1' ||
        (typeof defaultValue == 'boolean' ? defaultValue : false)),
  );

  const onChangeCheck = (): void => {
    const currentValue = !checkValue;
    const event = {
      target: {
        name: `${field.idName}|${field.formSectionId}`,
        value: currentValue,
      },
    };
    setCheckValue(currentValue);
    onChange(event);
    setTimeout(() => {
      onBlur();
    }, 500);
  };

  return (
    <>
      <FormControlLabel
        key={label}
        control={
          <Checkbox
            name={`${field.idName}|${field.formSectionId}`}
            onChange={onChangeCheck}
            checked={checkValue}
            color="default"
            disabled={isDisabled}
          />
        }
        label={label}
      />
    </>
  );
};

export default CheckBoxData;
