export enum ActionType {
  SET_PREINSPECTION_ID = 'SET_PREINSPECTION_ID',
  SET_PREINSPECTION_UNIQUEID = 'SET_PREINSPECTION_UNIQUEID',
  SET_PREINSPECTION_MODEL = 'SET_PREINSPECTION_MODEL',
  SET_PREINSPECTION_FORM = 'SET_PREINSPECTION_FORM',
  SET_PREINSPECTION_FORM_INIT = 'SET_PREINSPECTION_FORM_INIT',
  SET_PREINSPECTION_MEDIA = 'SET_PREINSPECTION_MEDIA',
  ADD_PHOTO = 'ADD_PHOTO',
  REMOVE_PHOTO = 'REMOVE_PHOTO',
  SET_ALERT_MODEL = 'SET_ALERT_MODEL',
  SET_EXTRACTED_PLATE = 'SET_EXTRACTED_PLATE',
  SET_EXTRACTED_VIN = 'SET_EXTRACTED_VIN',
  SET_EXTRACTED_RUV = 'SET_EXTRACTED_RUV',
}
