import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import reportWebVitals from './reportWebVitals';
import Router from './Router';
import './index.scss';

Sentry.init({
  dsn: 'https://653fd9f143704b87ac8bb23a0c70149e@o298640.ingest.sentry.io/6553912',
  environment: process.env.NODE_ENV,
  debug: process.env.NODE_ENV === 'development',
  // Per Sentry's recommendation:
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.0,

  // Filter out large breadcrumbs (otherwise Sentry yields 413 Too Much Data error)
  beforeSend(event) {
    if (event.breadcrumbs) {
      event.breadcrumbs = event.breadcrumbs.filter((crumb) => {
        return !(crumb.category === 'fetch' && crumb?.data?.url.length > 255);
      });
    }

    return event;
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
