import React, { FunctionComponent } from 'react';
import { IState } from '../types';
import reducer, { initialState } from './reducer';

export const AppContext = React.createContext<IState | any>(initialState);

export const AppProvider: FunctionComponent = (props): JSX.Element => {
  let _initialState = initialState;

  let stateBackup: IState;
  try {
    const appState = localStorage.getItem('appState') || '{}';
    stateBackup = JSON.parse(appState);
  } catch (error) {
    console.error("Couldn't read local storage state");
    stateBackup = initialState;
  }
  if (Object.keys(stateBackup).length) {
    _initialState = { ...initialState, ...stateBackup };
  }

  const [state, dispatch] = React.useReducer(reducer, _initialState);

  localStorage.setItem('appState', JSON.stringify(state));

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
