import { FC } from 'react';
import { BRAND, LOGOS } from '../../static/constants/brand';
import './Layout.scss';
import noImage from '../../assets/imgs/no-image.png';

const Layout: FC = ({ children }) => {
  return (
    <div className="layout">
      <header className="layout__header">
        <picture>
          <source srcSet={LOGOS[BRAND]} />
          <img src={noImage} alt="Application Logo" />
        </picture>
      </header>
      <main className="layout__main">{children}</main>
    </div>
  );
};

export default Layout;
