import './Finished.scss';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IAppContext } from '../types';
import { AppContext } from '../context/Context';
import { ReactComponent as FinishIcon } from '../assets/imgs/i-finalizado.svg';
import { ReactComponent as CompleteImage } from '../assets/imgs/complete.svg';
import { formatValue } from '../helpers/Utils';
import localforage from 'localforage';
import ProgressLoading from '../components/common/ProgressLoading';
import { requestPreInspectionFinish } from '../services/preInspect.service';
import ContinueButton from '../components/carousel/ContinueButton';
import { BRAND } from '../static/constants/brand';

const endTextOptions: { [key: string]: string } = {
  IS_CORREDOR: 'Su corredor se pondrá en contacto con ustedes.',
  IS_AUTOEXPRESS: 'Su póliza será enviada en breve.',
  DEFAULT:
    'Uno de nuestros agentes se estará comunicando contigo para los siguientes pasos.',
};

const PROCESS_MESSAGES = [
  'Un momento por favor, estamos procesando la información.',
  'Esto puede demorar un poco, un momento por favor.',
  'Estamos procesando la información, pronto continuaremos con la inspección.',
];

const PROCESS_MESSAGE_DURATION = 55000;

const FinishedScreen: FunctionComponent = (): JSX.Element => {
  const {
    state: { preInspectionModel },
  } = useContext(AppContext) as IAppContext;
  const [endText, setEndText] = useState(endTextOptions['DEFAULT']);
  const [loading, setLoading] = useState(false);
  const [initRequest, setInitRequest] = useState(true);
  const [requestFinishSuccess, setRequestFinishSuccess] = useState(false);
  const [resend, setResend] = useState(false);
  const [resultRequest, setResultRequest] = useState('');
  const [timeStampInit, setTimeStampInit] = useState(new Date());

  const hasURLToRedirect =
    preInspectionModel.preRedirectURL &&
    preInspectionModel.preRedirectURL.length > 0;

  const isCotizador =
    preInspectionModel.preEmisionType &&
    preInspectionModel.preEmisionType === 1;

  const getEndText = async () => {
    const endTextType: string | null = await localforage.getItem('endtext');
    if (endTextType && endTextOptions[endTextType.toUpperCase()]) {
      setEndText(endTextOptions[endTextType.toUpperCase()]);
    } else {
      setEndText(endTextOptions['DEFAULT']);
    }
  };

  const getCurrentTime = (): number => {
    const currentDate = new Date();
    const data = currentDate.getTime() - timeStampInit.getTime();
    const result: any = Number(data / 1000);

    return result;
  };

  const triggerExpireProcess = () => {
    setRequestFinishSuccess(false);
    setLoading(false);
    setEndText(
      'Estamos procesando su información, en unos minutos recibirá un correo con el resultado de su inspección y las instrucciones a seguir.',
    );
  };

  const finishProcess = async () => {
    setLoading(true);
    setResend(false);
    // Request a Finish result
    let resultMessage = await requestPreInspectionFinish(
      preInspectionModel.preInspectionId,
    );

    // eslint-disable-next-line no-console
    console.log(resultMessage);

    resultMessage =
      getCurrentTime() > PROCESS_MESSAGE_DURATION / 1000
        ? 'EXPIRE'
        : resultMessage;

    // eslint-disable-next-line no-console
    console.log(resultMessage, getCurrentTime());

    if (resultMessage !== undefined && resultMessage === 'WAITING') {
      // Recursive for wait response
      await finishProcess();
    } else if (resultMessage === undefined || resultMessage === 'EXPIRE') {
      triggerExpireProcess();
    } else if (
      resultMessage !== undefined &&
      (resultMessage.indexOf('SATISFACTORIO') > -1 ||
        resultMessage.indexOf('NO SATISFACTORIO') > -1)
    ) {
      setRequestFinishSuccess(true);
      setLoading(false);
      setResultRequest(resultMessage);
    } else if (
      resultMessage !== undefined &&
      resultMessage.indexOf('ERROR') > -1
    ) {
      setRequestFinishSuccess(false);
      setLoading(false);
      setResend(true);
      setResultRequest(resultMessage);
    }
  };

  const triggerToRedirect = () => {
    window.location.href = preInspectionModel.preRedirectURL;
  };

  useEffect(() => {
    getEndText();

    if (initRequest) {
      setTimeStampInit(new Date());
      setInitRequest(false);
      (async (): Promise<void> => {
        await finishProcess();
      })();
    }
  }, []);

  useEffect(() => {
    if (loading && getCurrentTime() > PROCESS_MESSAGE_DURATION) {
      triggerExpireProcess();
    }
  });

  return (
    <div className="finished">
      {loading && (
        <ProgressLoading
          messages={PROCESS_MESSAGES}
          duration={PROCESS_MESSAGE_DURATION}
        />
      )}
      {requestFinishSuccess &&
      resultRequest.indexOf('NO SATISFACTORIO') === -1 ? (
        <h2>¡Inspección Satisfactoria!</h2>
      ) : resultRequest.indexOf('NO SATISFACTORIO') > -1 ? (
        <h2>¡Inspección No Satisfactoria!</h2>
      ) : (
        <h2>¡Inspección Finalizada!</h2>
      )}
      <FinishIcon className="icon-finish theme-svg-icon" />
      <p className="finished__info">
        {resultRequest.indexOf('ERROR') > -1 ? (
          <label>
            Se detectaron inconvenientes en la conexión hacia nuestros sistemas.
            Favor proceda ha darle clic al siguiente botón para ejecutar el
            reenvío.
          </label>
        ) : requestFinishSuccess && BRAND === 'is' ? (
          resultRequest.indexOf('NO SATISFACTORIO') === -1 ? (
            <label>
              Su inspección ha sido <b>SATISFACTORIA</b>.<br />
              {hasURLToRedirect ? (
                <>
                  Para continuar con el proceso por favor haga clic en el
                  siguiente botón para continuar con la emisión de la póliza.
                </>
              ) : (
                endTextOptions['IS_AUTOEXPRESS']
              )}
            </label>
          ) : (
            BRAND === 'is' && (
              <label>
                Favor contáctenos al teléfono 206-4601 o escribirnos al correo
                <b>
                  {isCotizador
                    ? ' emitre@iseguros.com'
                    : ' mercadeorg@iseguros.com'}
                </b>
                .
              </label>
            )
          )
        ) : BRAND === 'is' ? (
          endTextOptions['DEFAULT']
        ) : (
          endText
        )}
      </p>
      <p>Reporte</p>
      <label id="pre-id">
        {'#' +
          (preInspectionModel !== null &&
          String(preInspectionModel?.preInspectionId).length > 0
            ? formatValue(
                preInspectionModel?.preInspectionId,
                '000000',
                '0',
                true,
              )
            : '000000')}
      </label>
      <div className="finished__image">
        <CompleteImage height={250} />
      </div>
      {requestFinishSuccess &&
        resultRequest.indexOf('NO SATISFACTORIO') === -1 &&
        hasURLToRedirect && (
          <ContinueButton onClick={triggerToRedirect}>Continuar</ContinueButton>
        )}

      {resend && (
        <>
          <p className="finished__info">
            <label>
              <i>
                (Si el problema persiste favor contáctenos al teléfono 206-4601
                o escribirnos al correo <b> emitre@iseguros.com</b>)
              </i>
            </label>
          </p>
          <ContinueButton onClick={finishProcess}>
            Reenviar Información
          </ContinueButton>
        </>
      )}
    </div>
  );
};

export default FinishedScreen;
